import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Tooltip, Typography } from 'antd';
import { isFunction } from 'lodash';
import { ArrowClockwise } from 'phosphor-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import VideoPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  APP_TYPES,
  APP_TYPE_OPTIONS,
  ASSET_CATEGORY,
  CLOUD_FRONT_CACHE_KEYS,
  LIST_TYPES,
  MODULES,
  ROUTES,
  UI_LABEL_TYPES
} from '../../common/constants';
import { getImageUrl } from '../../common/utils';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../menus/graphql/Mutations';
import { GET_UI_LABELS } from './graphql/Queries';

const variablesSelector = ({ limit, offset, search, appType }) => ({
  filter: {
    skip: offset,
    search,
    limit,
    appType
  }
});

const dataSelector = ({ uiLabelsAdmin }) => ({
  data: uiLabelsAdmin?.uiLabels ?? [],
  count: uiLabelsAdmin?.count ?? 0
});

const Action = ({ id, appType }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`${ROUTES?.UI_LABELS}/${appType}/${id}/edit`);
  };

  return (
    <Tooltip title="Edit UI Label">
      <Button type="text" className="text-btn" onClick={handleClick}>
        <EditOutlined />
      </Button>
    </Tooltip>
  );
};

const columns = ({ showPreview }) => [
  {
    title: 'Reference Image',
    dataIndex: 'referenceImage',
    ellipsis: true,
    width: 150,
    render: (_, { referenceImage, value, key }) => (
      <Image
        className="group-img-table pointer"
        blurHash={referenceImage?.blurhash}
        src={
          getImageUrl(referenceImage?.url, { height: 50, width: 80 }) ??
          VideoPlaceholder
        }
        alt={value}
        onClick={() => {
          if (isFunction(showPreview))
            showPreview({
              title: key,
              url: referenceImage?.url ?? VideoPlaceholder,
              type: ASSET_CATEGORY.IMAGE
            });
        }}
      />
    )
  },
  {
    title: 'Key',
    dataIndex: 'key'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    render: (value, { type }) =>
      type === UI_LABEL_TYPES.HTML ? (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: value
          }}
        />
      ) : (
        value
      )
  },
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: 150
  },
  {
    title: 'Action',
    dataIndex: 'key',
    render: (_, { key, appType }) => <Action id={key} appType={appType} />,
    width: 150
  }
];

const UILabels = () => {
  const filterProps = useFilterBar();
  const [appType, setAppType] = useState(APP_TYPES.WEB);
  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.UI_LABELS }
    }
  );

  const handleAppTypeChange = (value) => setAppType(value);

  return (
    <>
      <PageHeader menu={MODULES?.UI_LABELS} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search UI Labels' }}
        show={{ listModes: false, sort: false }}
      >
        <Typography.Text className="sort-by-title" type="secondary">
          App Type
        </Typography.Text>
        <AntdSelect
          placeholder="App Type"
          dropdownMatchSelectWidth={false}
          value={appType}
          options={APP_TYPE_OPTIONS}
          onChange={handleAppTypeChange}
        />
        <Tooltip title="Revalidate cache" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={{ ...filterProps.filters, appType }}
        listMode={LIST_TYPES.TABLE}
        query={GET_UI_LABELS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        keyField="key"
      />
    </>
  );
};

export default UILabels;
