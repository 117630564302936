import { useMutation, useQuery } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  APP_TYPES,
  APP_TYPE_OPTIONS,
  ASSET_CATEGORY,
  MODULES,
  ROUTES,
  UI_LABEL_TYPES
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import { Editor, SelectAsset } from '../videos/components/FormInputs';
import { UPDATE_UI_LABEL } from './graphql/Mutations';
import { GET_UI_LABEL } from './graphql/Queries';

const initialValues = {
  value: '',
  description: '',
  appType: APP_TYPES.WEB,
  referenceImage: {
    id: '',
    url: ''
  },
  asset: null
};

const EditUILabel = ({ history, match: { params } }) => {
  const { appType, labelKey } = params;
  const [form] = Form.useForm();
  const [type, setType] = useState(UI_LABEL_TYPES.STRING);

  const { data, loading: fetchingDetails } = useQuery(GET_UI_LABEL, {
    variables: {
      appType,
      key: labelKey
    },
    fetchPolicy: 'network-only'
  });

  const [updateUILabel, { loading }] = useMutation(UPDATE_UI_LABEL);

  useEffect(() => {
    if (!data) return;
    const { uiLabelAdmin } = data;

    form.setFieldsValue({
      value: uiLabelAdmin?.value ?? '',
      description: uiLabelAdmin?.description ?? '',
      appType: uiLabelAdmin?.appType ?? APP_TYPES.WEB,
      referenceImage: {
        id: uiLabelAdmin?.referenceImage?.id ?? '',
        url: uiLabelAdmin?.referenceImage?.url ?? ''
      }
    });
    setType(uiLabelAdmin?.type ?? UI_LABEL_TYPES.STRING);
  }, [data, form]);

  const handleSubmit = ({
    referenceImage,
    asset: _asset,
    appType: appTypeValue,
    ...rest
  }) => {
    updateUILabel({
      variables: {
        key: labelKey,
        appType: appTypeValue,
        data: {
          ...rest,
          referenceImageId: referenceImage?.id || null
        }
      }
    }).then(() => {
      history.push(ROUTES?.UI_LABELS);
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.UI_LABELS);
  };

  const assetInfo = {
    [UI_LABEL_TYPES.IMAGE]: {
      modalTitle: 'Select Image',
      categoryKey: ASSET_CATEGORY.IMAGE,
      btnText: 'Image',
      dataSelector: ({ id, url }) => ({
        id,
        url
      })
    },
    [UI_LABEL_TYPES.VIDEO]: {
      modalTitle: 'Select Video',
      categoryKey: ASSET_CATEGORY.VIDEO,
      btnText: 'VIDEO',
      dataSelector: ({ id, serviceImageThumbnail, serviceVideoThumbnail }) => ({
        id,
        url: serviceVideoThumbnail || serviceImageThumbnail
      })
    }
  }[type];

  return (
    <>
      <PageHeader menu={MODULES?.UI_LABELS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails}
          >
            {type !== UI_LABEL_TYPES.STRING && assetInfo && (
              <Form.Item name="asset">
                <SelectAsset
                  allowClear={false}
                  onChange={({ id }) => {
                    if (id) form.setFieldValue('value', id);
                  }}
                  {...assetInfo}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Value"
              name="value"
              rules={[
                {
                  required: true,
                  message: 'Please enter value!'
                }
              ]}
            >
              {type === UI_LABEL_TYPES.HTML ? (
                <Editor
                  modules={{
                    toolbar: [['bold', 'italic', 'underline', 'link']]
                  }}
                />
              ) : (
                <Input
                  placeholder="Enter value"
                  disabled={type !== UI_LABEL_TYPES.STRING}
                  readOnly={type !== UI_LABEL_TYPES.STRING}
                />
              )}
            </Form.Item>

            <Form.Item label="Description" name="description">
              <Input.TextArea rows={5} placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              label="App Type"
              name="appType"
              rules={[
                {
                  required: true,
                  message: 'Please select app type!'
                }
              ]}
            >
              <AntdSelect
                disabled
                options={APP_TYPE_OPTIONS}
                placeholder="Select app type"
              />
            </Form.Item>
            <Form.Item label="Reference Image" name="referenceImage">
              <SelectAsset
                modalTitle="Select Reference Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  disabled={loading || fetchingDetails}
                  loading={loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditUILabel;
