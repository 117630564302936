import { Form, Input } from 'antd';
import React from 'react';
import SectionAdd from '../sectionWrapper/SectionAdd';
import SectionWrapper from '../sectionWrapper/SectionWrapper';
import FormLayout from './FormLayout';
import LayoutRow from './LayoutRow';
import ModuleLayout from './ModuleLayout';
import TemplateLayout from './TemplateLayout';

function LatyoutWrapper() {
  return (
    <LayoutRow>
      <TemplateLayout>
        <SectionAdd />
        <SectionWrapper />
        <SectionAdd />
      </TemplateLayout>
      <ModuleLayout />
      <FormLayout>
        <Form layout="vertical">
          <div className="form-group">
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ message: 'Please Enter Last Name' }]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </div>
        </Form>
      </FormLayout>
    </LayoutRow>
  );
}
export default LatyoutWrapper;
