import { Space, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  FORM_TYPES,
  getDefaultPlaceholderValues,
  useEditPage
} from '../../context/EditPageProvider';

const AddModule = () => {
  const {
    pageModules,
    loadingPageModules: loading,
    fetchPageModules,
    addModule,
    setForm,
    setSelectionType,
    addIndex,
    settings
  } = useEditPage();

  useEffect(() => {
    fetchPageModules({
      variables: {
        filter: {
          appType: settings?.appType
        }
      }
    });
  }, []);

  const handleClick = (key) => {
    const data = getDefaultPlaceholderValues(key);

    if (data) {
      setForm({ type: FORM_TYPES.ADD, index: addIndex });
      addModule(data);
      setSelectionType(data.type);
    }
  };

  return (
    <Space direction="vertical" size="large">
      <Typography.Text className="title">Add a module:</Typography.Text>
      {loading ? (
        <Spin />
      ) : (
        <Space className="module-list" direction="vertical">
          {pageModules.map(({ key, name }) => (
            <Typography.Text onClick={() => handleClick(key)} key={key}>
              {name}
            </Typography.Text>
          ))}
        </Space>
      )}
    </Space>
  );
};

export default AddModule;
