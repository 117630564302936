import { Divider, Typography } from 'antd';
import React from 'react';
import { TagCarouseldata, VideoCarouselData } from '../DummyData';
import VideoCard from './VideoCard';
import VideoCarousel from './VideoCarousel';
import VideoGrid from './VideoGrid';
import VideoInfo from './VideoInfo';
import VideoList from './VideoList';

const { Title } = Typography;

function VideoWrapper() {
  return (
    <>
      <section>
        <Title level={4}>Video</Title>

        <VideoCard
          defaultValue={30}
          time="9:00"
          category="category"
          collection="part 1"
          title=" Title of Video Goes Here"
          date="12/12/22"
          speaker="Speaker Name"
          tags={['teg1', 'teg2', 'teg3']}
          tagBackground="bg-blue"
        />
      </section>
      <div className="s-40" />
      <section>
        <VideoCarousel
          sectionHeading="All Videos Carousel"
          headerLink="/"
          headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
          data={VideoCarouselData}
        />
      </section>
      <div className="s-40" />
      <section>
        <VideoGrid
          sectionHeading="All Videos Grid"
          headerLink="/"
          headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
          data={VideoCarouselData}
        />
      </section>
      <div className="s-40" />
      <VideoList
        sectionHeading="All Videos List"
        headerLink="/"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={VideoCarouselData}
      />
      <div className="s-40" />
      <VideoInfo
        category="Spiritual Practices"
        title="Orthodoxy"
        description="John Mark Comer explains a historic and personal account of where he's seen deconstruction lead."
        TagCarouseldata={TagCarouseldata}
        speaker={[
          {
            id: '1',
            speakerName: ' John Mark Comer 1',
            speakerImg:
              'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
          },
          {
            id: '2',
            speakerName: ' John Mark Comer 2',
            speakerImg:
              'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
          },
          {
            id: '3',
            speakerName: ' John Mark Comer 3',
            speakerImg:
              'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
          }
        ]}
      />
      <div className="s-40" />
      <Divider>Video responsive view Module</Divider>
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <VideoCarousel
              responsive
              sectionHeading="All Videos Carousel"
              headerLink="/"
              headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
              data={VideoCarouselData}
            />
          </div>
          <div className="responsive-grid">
            <VideoGrid
              responsive
              sectionHeading="All Videos Grid"
              headerLink="/"
              headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
              data={VideoCarouselData}
            />
          </div>
          <div className="responsive-grid">
            <VideoList
              responsive
              sectionHeading="All Videos List"
              headerLink="/"
              headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
              data={VideoCarouselData}
            />
          </div>
          <div className="s-40 w-full" />
          <div className="responsive-grid">
            <VideoList
              separator
              videoSelect
              responsive
              sectionHeading="All Videos List with select"
              headerLink="/"
              headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
              data={VideoCarouselData}
            />
          </div>
          <div className="responsive-grid">
            <VideoInfo
              responsive
              category="Spiritual Practices"
              title="Orthodoxy"
              description="John Mark Comer explains a historic and personal account of where he's seen deconstruction lead."
              TagCarouseldata={TagCarouseldata}
              speaker={[
                {
                  id: '1',
                  speakerName: ' John Mark Comer 1',
                  speakerImg:
                    'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                },
                {
                  id: '2',
                  speakerName: ' John Mark Comer 2',
                  speakerImg:
                    'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                },
                {
                  id: '3',
                  speakerName: ' John Mark Comer 3',
                  speakerImg:
                    'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                }
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
}
export default VideoWrapper;
