import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography
} from 'antd';
import React, { useState } from 'react';
import * as urlSlug from 'url-slug';
import {
  APP_TYPES,
  APP_TYPE_OPTIONS,
  ASSET_CATEGORY,
  MENU_TYPES,
  MENU_TYPES_OPTIONS,
  MODULES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  ROUTES,
  STATIC_PAGES
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import history from '../../../historyData';
import { GET_SLUGS } from '../../menus/graphql/Queries';
import { Select, SelectAsset } from '../../videos/components/FormInputs';
import { CREATE_PAGE } from '../graphql/Mutations';
import { Permissions, Switch } from './pageModules/moduleForms/FormInputs';

const { required } = formValidatorRules;

const initialValues = {
  title: '',
  slug: '',
  description: '',
  metaHeader: '',
  metaFooter: '',
  previewImage: {
    id: '',
    url: ''
  },
  appType: APP_TYPES.WEB,
  redirection: {
    type: MENU_TYPES.EXTERNAL,
    internalPageType: null,
    url: '',
    newTab: false,
    active: false
  }
};

const AddPage = () => {
  const [form] = Form.useForm();

  const redirectionActive = Form.useWatch(['redirection', 'active'], form);

  const type = Form.useWatch(['redirection', 'type'], form);
  const appType = Form.useWatch(['appType'], form);
  const internalPageType = Form.useWatch(
    ['redirection', 'internalPageType'],
    form
  );

  const [buttonLoading, setButtonLoading] = useState(false);

  const [createPageMutate] = useMutation(CREATE_PAGE, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.PAGES_MODULE);
  };

  const onFinish = async ({ previewImage, ...values }) => {
    setButtonLoading(true);
    try {
      const formData = {
        ...values,
        permissions: values?.permissions?.map(({ value }) => value),
        slug: values.slug.startsWith('/')
          ? values.slug.substring(1)
          : values.slug,
        type: 'CUSTOM',
        previewThumbnailId: previewImage?.id || null,
        ...(values?.redirection?.type === MENU_TYPES.INTERNAL && {
          redirection: {
            ...values?.redirection,
            url: values?.redirection?.url?.startsWith('/')
              ? values?.redirection?.url?.substring(1)
              : values?.redirection?.url
          }
        })
      };
      delete formData?.redirection?.slug;
      const response = await createPageMutate({
        variables: {
          data: {
            ...formData
          }
        }
      });
      if (response?.data?.createPage) {
        setButtonLoading(false);

        history?.replace(
          `${ROUTES?.PAGES_MODULE}/${response?.data?.createPage?.page?.id}/edit`
        );
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const handleNameChange = (e) => {
    form?.setFieldValue('slug', `/${urlSlug?.convert(e?.target?.value)}`);
  };

  return (
    <>
      <PageHeader menu={MODULES?.PAGES} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            name="create-page"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input
                placeholder="Enter title"
                disabled={buttonLoading}
                onChange={handleNameChange}
              />
            </Form.Item>
            <Form.Item
              label="App Type"
              name="appType"
              rules={[
                {
                  required: true,
                  message: 'Please select app type!'
                }
              ]}
            >
              <AntdSelect
                options={APP_TYPE_OPTIONS}
                placeholder="Select app type"
              />
            </Form.Item>
            <Form.Item
              name="slug"
              label="Page Slug"
              rules={[{ required, message: 'Please enter slug!' }]}
            >
              <Input placeholder="Enter slug" disabled={buttonLoading} />
            </Form.Item>

            <Row>
              <Col span={10} className="redirection-section">
                <Typography.Title level={4}>Redirect URL</Typography.Title>
                <Form.Item
                  className="m-0"
                  name={['redirection', 'active']}
                  valuePropName="checked"
                >
                  <Switch label="Active" />
                </Form.Item>
                <Form.Item
                  label="Type"
                  className="mt-16"
                  name={['redirection', 'type']}
                  rules={[
                    {
                      required: redirectionActive,
                      message: 'Please select type!'
                    }
                  ]}
                >
                  <AntdSelect
                    options={MENU_TYPES_OPTIONS}
                    placeholder="Select type"
                    onChange={(value) => {
                      form.setFieldValue(
                        ['redirection', 'newTab'],
                        value !== MENU_TYPES.INTERNAL
                      );
                      form.resetFields([
                        ['redirection', 'url'],
                        ['redirection', 'slug'],
                        ['redirection', 'internalPageType']
                      ]);
                    }}
                  />
                </Form.Item>
                {type === MENU_TYPES.INTERNAL && (
                  <>
                    <Form.Item
                      label="Page Type"
                      name={['redirection', 'internalPageType']}
                      rules={[
                        {
                          required:
                            redirectionActive && type === MENU_TYPES.INTERNAL,
                          message: 'Please select page type!'
                        }
                      ]}
                    >
                      <AntdSelect
                        options={PAGE_TYPES_OPTIONS}
                        placeholder="Select page type"
                        onChange={() => {
                          form.resetFields([
                            ['redirection', 'url'],
                            ['redirection', 'slug']
                          ]);
                        }}
                      />
                    </Form.Item>
                    {internalPageType && (
                      <>
                        {internalPageType === PAGE_TYPES.STATIC ? (
                          <Form.Item
                            label="Page"
                            name={['redirection', 'slug']}
                            rules={[
                              {
                                required:
                                  redirectionActive &&
                                  type === MENU_TYPES.INTERNAL,
                                message: 'Please select page!'
                              }
                            ]}
                          >
                            <AntdSelect
                              options={STATIC_PAGES}
                              onChange={(value) => {
                                form.setFieldValue(
                                  ['redirection', 'url'],
                                  `/${value}`
                                );
                                form.validateFields([['redirection', 'url']]);
                              }}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            label="Page"
                            name={['redirection', 'slug']}
                            rules={[
                              {
                                required:
                                  redirectionActive &&
                                  type === MENU_TYPES.INTERNAL,
                                message: 'Please select page!'
                              }
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              query={GET_SLUGS}
                              variablesSelector={(filter) => ({
                                filter: {
                                  ...filter,
                                  pageType: internalPageType,
                                  appType
                                }
                              })}
                              dataSelector={(data) => {
                                return (
                                  data?.slugs?.slugs?.map(({ slug }) => ({
                                    label: `/${slug}`,
                                    value: slug
                                  })) ?? []
                                );
                              }}
                              keys={{
                                data: 'slugs',
                                records: 'slugs',
                                count: 'count'
                              }}
                              onChange={({ value }) => {
                                form.setFieldValue(
                                  ['redirection', 'url'],
                                  `/${value}`
                                );
                                form.validateFields([['redirection', 'url']]);
                              }}
                            />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                )}
                <Form.Item
                  label="URL"
                  name={['redirection', 'url']}
                  rules={[
                    {
                      required: redirectionActive,
                      message: 'Please enter URL!'
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter url"
                    readOnly={type === MENU_TYPES.INTERNAL}
                    disabled={type === MENU_TYPES.INTERNAL}
                  />
                </Form.Item>
                <Form.Item
                  className="m-0"
                  name={['redirection', 'newTab']}
                  valuePropName="checked"
                >
                  <Switch label="Open In New Tab" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="description" label="Page Description">
              <Input placeholder="Enter description" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item label="Page Sharing Thumbnail" name="previewImage">
              <SelectAsset
                modalTitle="Select Page Sharing Thumbnail"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image Thumbnail"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>

            <Form.Item name="metaHeader" label="Meta Header">
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item name="metaFooter" label="Meta Footer">
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <Permissions />

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPage;
