import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;
function SmallCard({ src, heading, description }) {
  return (
    <div className="small-card">
      {src ? <img src={src} alt="" /> : null}
      <div className="card-description  bg-n-100 ">
        {heading ? <Text className="heading-h7 block">{heading}</Text> : null}
        {description ? (
          <Text className="text-tiny mb-4 block card-dc">{description}</Text>
        ) : null}
      </div>
    </div>
  );
}
export default SmallCard;
