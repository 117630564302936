import React from 'react';
import { useParams } from 'react-router-dom';
import { MODULE_TYPES } from '../../../../common/constants';
import FormLayout from '../../../component/layout/FormLayout';
import { FORM_TYPES, useEditPage } from '../../context/EditPageProvider';
import { usePagePreview } from '../../context/PreviewPageProvider';
import AddModule from './AddModule';
import AuthorForm from './moduleForms/AuthorForm';
import CTAForm from './moduleForms/CTAForm';
import CollectionForm from './moduleForms/CollectionForm';
import CopyHeavyForm from './moduleForms/CopyHeavyForm';
import DonationForm from './moduleForms/DonationForm';
import FAQForm from './moduleForms/FAQForm';
import HtmlTextBlockForm from './moduleForms/HtmlTextBlockForm';
import InformationForm from './moduleForms/InformationForm';
import LeadGenForm from './moduleForms/LeadGenForm';
import QuoteForm from './moduleForms/QuoteForm';
import Settings from './moduleForms/Settings';
import SpacerForm from './moduleForms/SpacerForm';
import TagForm from './moduleForms/TagForm';
import TopicForm from './moduleForms/TopicForm';
import VideoForm from './moduleForms/VideoForm';

const Modules = {
  [MODULE_TYPES.SETTINGS]: Settings,
  [MODULE_TYPES.ADD]: AddModule,
  // Videos
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_LIST]: VideoForm,
  [MODULE_TYPES.VIDEO_GRID]: VideoForm,
  [MODULE_TYPES.FEATURED_VIDEO]: VideoForm,
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: VideoForm,
  [MODULE_TYPES.VIDEO_PLAYER]: VideoForm,
  // Live Video Player
  [MODULE_TYPES?.LIVE_VIDEO_PLAYER]: VideoForm,
  // Collections
  [MODULE_TYPES.COLLECTION_CAROUSEL]: CollectionForm,
  [MODULE_TYPES.COLLECTION_GRID]: CollectionForm,
  [MODULE_TYPES.COLLECTION_LIST]: CollectionForm,
  [MODULE_TYPES.FEATURED_COLLECTION]: CollectionForm,
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: CollectionForm,
  // Authors
  [MODULE_TYPES.AUTHOR_CAROUSEL]: AuthorForm,
  [MODULE_TYPES.AUTHOR_LIST]: AuthorForm,
  [MODULE_TYPES.AUTHOR_GRID]: AuthorForm,
  [MODULE_TYPES.FEATURED_AUTHOR]: AuthorForm,
  // Tags
  [MODULE_TYPES.TAG_CAROUSEL]: TagForm,
  [MODULE_TYPES.TAG_CLOUD]: TagForm,
  [MODULE_TYPES.FEATURED_TAG]: TagForm,
  // Topics
  [MODULE_TYPES.TOPIC_CAROUSEL]: TopicForm,
  [MODULE_TYPES.TOPIC_LIST]: TopicForm,
  [MODULE_TYPES.TOPIC_GRID]: TopicForm,
  [MODULE_TYPES.FEATURED_TOPIC]: TopicForm,
  // CTA
  [MODULE_TYPES.CTA_BLOCK]: CTAForm,
  [MODULE_TYPES.CTA_EVENT_BLOCK]: CTAForm,
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: CTAForm,
  [MODULE_TYPES.CTA_BANNER]: CTAForm,
  [MODULE_TYPES.HERO]: CTAForm,
  // Donation
  [MODULE_TYPES.DONATION]: DonationForm,
  // Copy Heavy
  [MODULE_TYPES.COPY_HEAVY]: CopyHeavyForm,
  [MODULE_TYPES.COPY_HEAVY_HERO]: CopyHeavyForm,
  // Quote
  [MODULE_TYPES.QUOTE]: QuoteForm,
  // Information
  [MODULE_TYPES.INFORMATION]: InformationForm,
  // Lead Gen
  [MODULE_TYPES.LEAD_GEN]: LeadGenForm,
  // Contact Us
  [MODULE_TYPES.CONTACT_US]: LeadGenForm,
  // FAQs
  [MODULE_TYPES.FAQ]: FAQForm,
  // Spacer
  [MODULE_TYPES.SPACER]: SpacerForm,
  // HTML Text Block
  [MODULE_TYPES.HTML_TEXT_BLOCK]: HtmlTextBlockForm
};

const RightPanel = () => {
  const {
    selectionType,
    form,
    clearRightPanel,
    updateModule,
    deleteModule,
    updateModuleSettings,
    isDefaultPage,
    pageType,
    appType
  } = useEditPage();
  const { refetch } = usePagePreview();
  const { id } = useParams();

  const handleCancel = () => {
    if (form.type === FORM_TYPES.ADD) {
      deleteModule(form.index);
    }
    updateModuleSettings(form?.defaultValues?.moduleData?.settings ?? null);
    clearRightPanel();
  };

  const handleSuccess = (data) => {
    updateModule(data);
    clearRightPanel();
    refetch();
  };

  if (!selectionType) return <FormLayout />;

  const FormComponent = Modules[selectionType];
  if (!FormComponent) return <FormLayout />;

  if (selectionType === MODULE_TYPES.ADD)
    return (
      <FormLayout>
        <FormComponent />
      </FormLayout>
    );

  return (
    <FormLayout>
      <FormComponent
        onSettingsChange={updateModuleSettings}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
        pageId={id}
        form={form}
        type={selectionType}
        isDefaultPage={isDefaultPage}
        pageType={pageType}
        appType={appType}
      />
    </FormLayout>
  );
};

export default RightPanel;
