import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ASSET_CATEGORY, MODULES, ROUTES } from '../../../common/constants';
import {
  Blurhash,
  fileUpload,
  formValidatorRules
} from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import ProgressBar from '../../../components/ProgressBar';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';
import { FileUpload } from './components';

const initialValues = {
  title: '',
  description: '',
  icon: []
};

const AddEditIcon = ({ match: { params }, history }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();
  const { iconId } = params;
  const isEdit = !!iconId;

  const [addEditIcon] = useMutation(isEdit ? UPDATE_ASSET : CREATE_ASSET);
  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL, {
    fetchPolicy: 'network-only'
  });

  const [fetchImage, { loading: fetchingDetails }] = useLazyQuery(GET_ASSET, {
    fetchPolicy: 'network-only'
  });

  const showProgress = progress !== 0 && progress !== 100;

  useEffect(() => {
    if (isEdit && !!iconId) {
      fetchImage({
        variables: {
          where: {
            id: iconId
          }
        }
      }).then(({ data, error }) => {
        if (!error && data) {
          form.setFieldsValue({
            title: data?.asset?.title ?? '',
            description: data?.asset?.description ?? '',
            icon: []
          });
        }
      });
    }
  }, [isEdit, iconId, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const { icon, ...restValues } = values;

    const payload = {
      ...restValues,
      categoryKey: ASSET_CATEGORY.ICON
    };
    try {
      if (icon?.length) {
        const file = icon?.[0]?.originFileObj;
        const { data, error } = await getSignedUrl({
          variables: {
            data: {
              fileName: file?.name?.replace(/\s/g, '_'),
              contentType: file?.type,
              assetType: ASSET_CATEGORY?.ICON
            }
          }
        });
        if (error) throw Error(error);
        if (data && data?.getAssetUploadSignedUrl) {
          await fileUpload(
            data?.getAssetUploadSignedUrl?.signedUrl,
            file,
            setProgress
          );
          payload.key = data?.getAssetUploadSignedUrl?.key;
          payload.contentType = file?.type;
          payload.blurhash = await Blurhash.encode(file);
        }
      }
      addEditIcon({
        variables: {
          data: payload,
          ...(isEdit && {
            where: {
              id: iconId
            }
          })
        }
      }).then(() => {
        setProgress(0);
        setLoading(false);
        history.push(ROUTES?.ICONS);
      });
    } catch (err) {
      setProgress(0);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    history.push(ROUTES?.ICONS);
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const checkForStroke = (svgContent) => {
    // Parse the SVG content and check for the stroke attribute
    // eslint-disable-next-line no-undef
    const parser = new DOMParser();
    const svgDoc = parser?.parseFromString(svgContent, 'image/svg+xml');
    const svgElement = svgDoc?.documentElement;
    const hasStroke = hasAttributeRecursive(svgElement, 'stroke');
    return hasStroke;
  };

  function hasAttributeRecursive(element, attribute) {
    if (element.hasAttribute(attribute)) {
      return true;
    }
    const { children } = element;
    for (let i = 0; i < children.length; i += 1) {
      if (hasAttributeRecursive(children[i], attribute)) {
        return true;
      }
    }

    return false;
  }

  return (
    <>
      <PageHeader menu={MODULES?.ICONS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="Title"
              name="title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input placeholder="Enter title" />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              name="icon"
              label="Icon"
              rules={[
                { required: !isEdit, message: 'Please select icon!' },
                {
                  message:
                    'Svg you uploaded contains stroke please reupload the one without strokes.',
                  validator: async (_, value) => {
                    const { originFileObj: file } = value?.[0] || {};
                    if (file) {
                      try {
                        const svgContent = await readFileContent(file);
                        const hasStroke = checkForStroke(svgContent);
                        if (hasStroke) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('Some message here');
                        }
                        return Promise.resolve();
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error('Error reading file:', error);
                      }
                    }
                  }
                }
              ]}
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
            >
              <FileUpload
                maxCount={1}
                accept=".png, .svg"
                helperText=".png, .svg"
              />
            </Form.Item>
            {showProgress && (
              <Form.Item>
                <ProgressBar progress={progress} />
              </Form.Item>
            )}
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={loading || fetchingDetails}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={loading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditIcon;
