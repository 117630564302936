import { Button, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function FeaturedCard({ src, title, heading, primary }) {
  return (
    <div className="featuredcard relative">
      <img src={src} alt="" />
      <div className="featuredcard-dec">
        {title ? (
          <Text className="text-md text-lighttheme block">{title}</Text>
        ) : null}
        {heading ? (
          <Text className="heading-h4 block text-n-1000 mb-24">{heading}</Text>
        ) : null}

        {primary ? (
          <Button
            className="button-l text-n-100 cs-button bg-n-900 hbg-red hc-n-0 b-0"
            size="small"
            block
          >
            {primary}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
export default FeaturedCard;
