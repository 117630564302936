import { gql } from '@apollo/client';

export const IMPORT_JOBS = gql`
  query importJobs($filter: ExportJobsFilter!) {
    importJobs(filter: $filter) {
      count
      data {
        id
        status
        fileUrl
        resultFileUrl
        createdAt
      }
    }
  }
`;

export const IMPORT_JOB_SIGNED_URL = gql`
  query getImportJobCsvUploadSignedUrl(
    $data: GetImportFileUploadSignedUrlInput!
  ) {
    getImportJobCsvUploadSignedUrl(data: $data) {
      uid
      signedUrl
      key
    }
  }
`;
