import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Modal, Select, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { LIST_TYPES, MODULES } from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { UPDATE_CONTACT_INQUIRY_STATUS } from './graphql/Mutations';
import {
  GET_CONTACT_INQUIRIES,
  GET_CONTACT_INQUIRY_KEYS
} from './graphql/Queries';

const STATUS_OPTIONS = [
  {
    label: 'OPEN',
    value: 'OPEN'
  },
  {
    label: 'RESOLVED',
    value: 'RESOLVED'
  },
  {
    label: 'UNRESOLVED',
    value: 'UNRESOLVED'
  }
];

const variablesSelector = ({ limit, offset, search, key, status }) => ({
  filter: {
    skip: offset,
    search,
    limit,
    key,
    status
  }
});

const dataSelector = ({ contactInquiries }) => ({
  data: contactInquiries?.contactInquiries ?? [],
  count: contactInquiries?.count ?? 0
});

const UpdateStatusModal = ({ open, id, onClose, status, onSuccess }) => {
  const [updateStatus, { loading }] = useMutation(
    UPDATE_CONTACT_INQUIRY_STATUS
  );
  const handleSubmit = (value) => {
    updateStatus({
      variables: {
        id,
        status: value?.status
      }
    }).then(onSuccess);
  };
  return (
    <Modal
      destroyOnClose
      okButtonProps={{
        className: 'text-btn',
        form: 'update-status-form',
        htmlType: 'submit',
        loading,
        type: 'text'
      }}
      cancelButtonProps={{
        className: 'text-btn2',
        type: 'text'
      }}
      title="Update Status"
      open={open}
      onCancel={onClose}
    >
      <Form
        initialValues={{
          status
        }}
        layout="vertical"
        id="update-status-form"
        onFinish={handleSubmit}
      >
        <Form.Item name="status" label="Status">
          <Select options={STATUS_OPTIONS} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Action = ({ id, status, refresh }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSuccess = () => {
    refresh();
    closeModal();
  };

  return (
    <>
      <UpdateStatusModal
        open={isOpen}
        id={id}
        status={status}
        onClose={closeModal}
        onSuccess={handleSuccess}
      />
      <Tooltip title="Update Status">
        <Button
          className="text-btn d-flex align-center justify-center"
          type="text"
          onClick={openModal}
        >
          <EditOutlined />
        </Button>
      </Tooltip>
    </>
  );
};

const columns = ({ refresh }) => [
  {
    title: 'F Name',
    dataIndex: 'firstName',
    ellipsis: true
  },
  {
    title: 'L Name',
    dataIndex: 'lastName',
    ellipsis: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    ellipsis: true,
    render: (value) => <a href={`mailto:${value}`}>{value}</a>
  },
  {
    title: 'Key',
    dataIndex: 'key'
  },
  {
    title: 'Inquiry',
    dataIndex: 'inquiry',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status'
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (_, record) => <Action {...record} refresh={refresh} />
  }
];

const ContactInquiry = () => {
  const filterProps = useFilterBar();
  const { data: { dataCollectionKeys = [] } = {}, loading } = useQuery(
    GET_CONTACT_INQUIRY_KEYS,
    {
      fetchPolicy: 'network-only'
    }
  );
  const [key, setKey] = useState(null);
  const [status, setStatus] = useState(null);

  const KEY_OPTIONS = useMemo(
    () =>
      dataCollectionKeys?.map((value) => ({
        label: value,
        value
      })) ?? [],
    [dataCollectionKeys]
  );

  const handleKeyChange = (val) => {
    setKey(val);
  };

  const handleStatusChange = (val) => {
    setStatus(val);
  };

  return (
    <>
      <PageHeader menu={MODULES?.CONTACT_INQUIRY} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Conversation' }}
        show={{ listModes: false, sort: false }}
      >
        <Select
          allowClear
          value={key}
          loading={loading}
          dropdownMatchSelectWidth={false}
          placeholder="Key"
          options={KEY_OPTIONS}
          onChange={handleKeyChange}
        />
        <Select
          allowClear
          value={status}
          dropdownMatchSelectWidth={false}
          placeholder="Status"
          options={STATUS_OPTIONS}
          onChange={handleStatusChange}
        />
      </FilterBar>
      <PageList
        filters={{ ...filterProps.filters, status, key }}
        listMode={LIST_TYPES.TABLE}
        query={GET_CONTACT_INQUIRIES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={20}
      />
    </>
  );
};

export default ContactInquiry;
