import { gql } from '@apollo/client';

export const COMMON_ASSET_FIELDS = gql`
  fragment CommonAssetFields on Asset {
    id
    url
    blurhash
  }
`;

export const GET_TAGS = gql`
  query tagsAdmin($filter: TagsFilter) {
    tagsAdmin(filter: $filter) {
      count
      tags {
        key
        name
      }
    }
  }
`;

export const GET_TOPICS = gql`
  query topicsAdmin($filter: TopicsFilter) {
    topicsAdmin(filter: $filter) {
      count
      topics {
        id
        name
        primaryColor
      }
    }
  }
`;

export const GET_AUTHORS = gql`
  query authorsAdmin(
    $offset: Int
    $limit: Limit
    $search: String = ""
    $isActive: Boolean = true
  ) {
    authorsAdmin(
      filter: { skip: $offset, limit: $limit, search: $search }
      where: { isActive: $isActive }
    ) {
      count
      authors {
        id
        firstName
        lastName
        primaryColor
        image {
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_VIDEO = gql`
  ${COMMON_ASSET_FIELDS}
  query videoAdmin($id: ID!) {
    videoAdmin(where: { id: $id }) {
      title
      description
      slug
      tags {
        key
        name
      }
      topics {
        id
        name
      }
      page {
        id
        isDefault
      }
      ottPage {
        id
        isDefault
      }
      mobilePage {
        id
        isDefault
      }
      status
      contentRating
      autoPublishAt
      metaHeader
      metaFooter
      authors {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      isLive
      liveStatus
      video {
        id
        serviceVideoThumbnail
        serviceImageThumbnail
        isLive
      }
      image {
        ...CommonAssetFields
      }
      imageThumbnail {
        ...CommonAssetFields
      }
      videoThumbnail {
        ...CommonAssetFields
      }
      timelineThumbnail {
        ...CommonAssetFields
      }
      documents {
        ...CommonAssetFields
        title
      }
      audioTracks {
        ...CommonAssetFields
        title
      }
      textTracks {
        ...CommonAssetFields
        title
      }
      resources {
        type
        content
      }
      permissions
    }
  }
`;

export const GET_VIDEOS_MODULES = gql`
  query videosAdmin($filter: VideosFilter, $sort: VideosSort) {
    videosAdmin(filter: $filter, sort: $sort) {
      count
      videos {
        id
        title
        isLive
        status
        liveStatus
        description
        createdAt
        imageThumbnail {
          url
          blurhash
        }
      }
    }
  }
`;
