import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import { isObject } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_TYPES, ROUTES } from '../common/constants';
import { CREATE_PAGE } from '../modules/pages/graphql/Mutations';

const TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

export const useCreatePage = (type) => {
  const history = useHistory();
  const [state, setState] = useState({
    pages: {
      [APP_TYPES.WEB]: { id: '', type: '', slug: '' },
      [APP_TYPES.OTT]: { id: '', type: '', slug: '' },
      [APP_TYPES.MOBILE]: { id: '', type: '', slug: '' }
    },
    modal: { isOpen: false }
  });
  const [selectedAppType, setSelectedAppType] = useState(APP_TYPES.WEB);

  const { pages } = state;
  const page = pages[selectedAppType];
  const [createPage, { loading: loadingCreatePage }] = useMutation(CREATE_PAGE);
  const loading = loadingCreatePage;

  const setPage = useCallback((pageData) => {
    if (!isObject(pageData)) return;

    setState((prev) => ({
      ...prev,
      pages: Object.fromEntries(
        Object.entries(pageData).map(([key, value]) => [
          key,
          {
            id: value?.id,
            type:
              value?.isDefault || !('isDefault' in value)
                ? TYPES.CREATE
                : TYPES.UPDATE,
            slug: value?.slug?.startsWith('/')
              ? value?.slug?.substring?.(1)
              : value?.slug
          }
        ])
      )
    }));
  }, []);

  const setShowModal = (value) => {
    setState((prev) => ({
      ...prev,
      modal: {
        isOpen: value
      }
    }));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateToEditPage = () => {
    if (page.id) history.push(`${ROUTES?.PAGES_MODULE}/${page.id}/edit`);
  };

  const handleCreatePage = (values) => {
    if (type)
      createPage({
        variables: {
          data: {
            ...values,
            slug: page.slug,
            type,
            appType: selectedAppType
          }
        }
      })
        .then(({ data: { createPage: { page: pageData } = {} } }) => {
          if (pageData?.id)
            history.push(`${ROUTES?.PAGES_MODULE}/${pageData?.id}/edit`);
        })
        .catch();
  };

  const getModalProps = ({ title = 'Create Page' }) => ({
    title,
    isOpen: state.modal.isOpen,
    onClose: closeModal,
    onSubmit: handleCreatePage,
    loading
  });

  return {
    page,
    TYPES,
    setPage,
    openModal,
    closeModal,
    getModalProps,
    navigateToEditPage,
    selectedAppType,
    setSelectedAppType
  };
};

const initialValues = {
  title: '',
  description: ''
};

const CreatePageModal = ({
  title = '',
  isOpen,
  onClose,
  onSubmit,
  loading
}) => {
  const [form] = Form.useForm();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      destroyOnClose
      okButtonProps={{
        className: 'text-btn',
        form: 'create-page',
        htmlType: 'submit',
        loading,
        disabled: loading,
        type: 'text'
      }}
      cancelButtonProps={{
        className: 'text-btn2',
        type: 'text'
      }}
      title={title}
      open={isOpen}
      onCancel={handleClose}
    >
      <Form
        id="create-page"
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter title!'
            }
          ]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePageModal;
