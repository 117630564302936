import { Button, Select, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowClockwise } from 'phosphor-react';
import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EXPORT_JOB_OPTIONS,
  EXPORT_JOB_TYPES,
  FULL_DATE_TIME_FORMAT,
  LIST_TYPES,
  MODULES,
  ROUTES,
  STATUS_COLORS
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { IMPORT_JOBS } from './graphql/Queries';

const variablesSelector = ({ offset, limit, type }) => ({
  filter: {
    skip: offset,
    limit,
    type
  }
});

const dataSelector = ({ importJobs }) => ({
  data: importJobs?.data || [],
  count: importJobs?.count || 0
});

const URL = ({ value }) =>
  value ? (
    <a href={value} download type="text">
      {value}
    </a>
  ) : (
    '-'
  );

const columns = [
  {
    title: 'Job ID',
    dataIndex: 'id',
    key: 'id',
    width: 380
  },
  {
    title: 'File',
    dataIndex: 'fileUrl',
    key: 'fileUrl',
    ellipsis: true,
    render: (value) => <URL value={value} />
  },
  {
    title: 'Result File',
    dataIndex: 'resultFileUrl',
    key: 'resultFileUrl',
    ellipsis: true,
    render: (value) => <URL value={value} />
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
        {value}
      </Tag>
    ),
    width: 150
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    render: (value) =>
      value ? moment(value).format(FULL_DATE_TIME_FORMAT) : ''
  }
];

const ImportJobs = () => {
  const ref = useRef();
  const history = useHistory();
  const [type, setType] = useState(EXPORT_JOB_TYPES.USER);

  const filters = useMemo(
    () => ({
      type
    }),
    [type]
  );
  const handleTypeChange = (value) => setType(value);

  const handleShowModal = () => {
    history.push(`${ROUTES?.IMPORT_JOBS}/add`);
  };

  const handleRefresh = () => {
    if (ref?.current?.refresh) {
      ref?.current?.refresh();
    }
  };

  return (
    <>
      <PageHeader
        menu={MODULES?.IMPORT_JOBS}
        handleShowModal={handleShowModal}
      />
      <div className="assets-filter-wrapper">
        <div className="asset-filter-left" />
        <div className="asset-filter-right">
          <div className="asset-filter">
            <Select
              value={type}
              dropdownMatchSelectWidth={false}
              placeholder="Select type"
              options={EXPORT_JOB_OPTIONS}
              onChange={handleTypeChange}
              className="mr-8"
            />
            <Tooltip title="Refresh" placement="left">
              <Button
                className="text-btn d-flex align-center justify-center"
                type="text"
                icon={<ArrowClockwise size={24} />}
                onClick={handleRefresh}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <PageList
        ref={ref}
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={IMPORT_JOBS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={30}
      />
    </>
  );
};

export default ImportJobs;
