import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddImportJob from './AddImportJob';
import ImportJobs from './ImportJobs';

const ImportJobsWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.IMPORT_JOBS} component={ImportJobs} />
      <Route
        exact
        path={`${ROUTES?.IMPORT_JOBS}/add`}
        component={AddImportJob}
      />
    </Switch>
  );
};

export default ImportJobsWrapper;
