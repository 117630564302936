import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { CategorySliderData } from '../DummyData';
import CategoryCard from './CategoryCard';
import CategoryCarousel from './CategoryCarousel';
import CategoryGrid from './CategoryGrid';
import CategoryList from './CategoryList';

const { Title } = Typography;
function CategoryWrapper() {
  return (
    <>
      <section>
        <Title level={4}>Category</Title>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <CategoryCard topic="Topic" backgroundColor="bg-n-600" href="/" />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <CategoryCard
              topic="Topic"
              backgroundColor="bg-red"
              grid
              href="/"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <CategoryCard
              topic="Topic"
              backgroundColor="bg-moss"
              list
              href="/"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <CategoryCard
              topic="Topic"
              backgroundColor="bg-yellow-2"
              lending
              href="/"
            />
          </Col>
        </Row>
      </section>
      <div className="s-40" />
      <CategoryCarousel
        sectionHeading="Category Carousel"
        headerLink="/"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={CategorySliderData}
      />
      <div className="s-40" />
      <CategoryGrid
        sectionHeading="Category Grid"
        headerLink="/"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={CategorySliderData}
      />
      <div className="s-40" />
      <CategoryList
        sectionHeading="Category List"
        headerLink="/"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={CategorySliderData}
      />
      <div className="s-40" />
      <Divider>Category responsive view Module</Divider>
      <div className="s-40" />
      <div className="d-flex flex-wrap">
        <div className="responsive-grid">
          <CategoryCard
            responsive
            topic="Topic"
            backgroundColor="bg-yellow-2"
            lending
            href="/"
          />
        </div>
      </div>
      <div className="s-40" />
      <div className="d-flex flex-wrap">
        <div className="responsive-grid">
          <CategoryCarousel
            responsive
            sectionHeading="Category Carousel"
            headerLink="/"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
            data={CategorySliderData}
          />
        </div>
        <div className="responsive-grid">
          <CategoryGrid
            responsive
            sectionHeading="Category Grid"
            headerLink="/"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
            data={CategorySliderData}
          />
        </div>
        <div className="responsive-grid">
          <CategoryList
            responsive
            sectionHeading="Category List"
            headerLink="/"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
            data={CategorySliderData}
          />
        </div>
      </div>
    </>
  );
}
export default CategoryWrapper;
