import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  APP_TYPES,
  APP_TYPE_OPTIONS,
  MODULES,
  PAGE_TYPES,
  ROUTES
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CreatePageModal, {
  useCreatePage
} from '../../../components/CreatePageModal';
import PageHeader from '../../../components/PageHeader';
import { SlugInput } from '../topics/components/FormInputs';
import { CREATE_TAG, UPDATE_TAG } from './graphql/Mutations';
import { GET_TAG } from './graphql/Queries';

const initialValues = {
  name: '',
  slug: '/',
  metaHeader: '',
  metaFooter: ''
};

const AddEditTags = ({ match: { params }, history }) => {
  const [form] = Form.useForm();
  const { tagId } = params;

  const {
    page,
    TYPES,
    setPage,
    getModalProps,
    openModal,
    navigateToEditPage,
    selectedAppType,
    setSelectedAppType
  } = useCreatePage(PAGE_TYPES.TAG);

  const isEdit = !!tagId;

  const [fetchTagDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_TAG,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateTag, { loading }] = useMutation(
    isEdit ? UPDATE_TAG : CREATE_TAG
  );

  useEffect(() => {
    if (isEdit && !!tagId) {
      fetchTagDetails({
        variables: {
          key: tagId
        }
      }).then((res) => {
        const tag = res.data?.tagAdmin;
        if (tag) {
          const {
            slug,
            page: pageData,
            metaHeader,
            metaFooter,
            name,
            ottPage,
            mobilePage
          } = tag;
          form.setFieldsValue({
            name: name ?? '',
            slug: slug.startsWith('/') ? slug : `/${slug}`,
            metaHeader: metaHeader ?? '',
            metaFooter: metaFooter ?? ''
          });
          const customPages = {
            [APP_TYPES.WEB]: { ...pageData, slug },
            [APP_TYPES.OTT]: { ...ottPage, slug },
            [APP_TYPES.MOBILE]: { ...mobilePage, slug }
          };
          setPage(customPages);
        }
      });
    }
  }, [fetchTagDetails, tagId, form, isEdit]);

  const handleNameChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const handleCancel = () => {
    history.push(ROUTES?.TAGS);
  };

  const handleSubmit = ({ ...values }) => {
    const slug = values.slug.startsWith('/')
      ? values.slug.substring(1)
      : values.slug;

    addUpdateTag({
      variables: {
        data: {
          ...values,
          slug
        },
        ...(isEdit && {
          key: tagId
        })
      }
    })
      .then(() => {
        history.push(ROUTES?.TAGS);
      })
      .catch();
  };

  return (
    <>
      <CreatePageModal {...getModalProps({ title: 'Create Tag Page' })} />
      <PageHeader menu={MODULES?.LABELS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={fetchingDetails}
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                formValidatorRules?.required('Please enter name!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input placeholder="Enter name" onChange={handleNameChange} />
            </Form.Item>

            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                }
              ]}
            >
              <SlugInput />
            </Form.Item>
            <Form.Item name="metaHeader" label="Meta Header">
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item name="metaFooter" label="Meta Footer">
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <div className="d-flex button-section mb-8">
              <Space>
                <Button
                  loading={fetchingDetails || loading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>

                <Button
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
            {isEdit && page.type && (
              <Select
                options={APP_TYPE_OPTIONS}
                placeholder="Select app type"
                className="mr-12 app-type-select"
                defaultValue={selectedAppType}
                dropdownMatchSelectWidth={false}
                onChange={(e) => {
                  setSelectedAppType(e);
                }}
              />
            )}
            {isEdit && page.type === TYPES.CREATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={openModal}
              >
                Create Custom Page
              </Button>
            )}
            {isEdit && page.type === TYPES.UPDATE && (
              <Button
                disabled={fetchingDetails}
                htmlType="button"
                type="text"
                className="text-btn"
                onClick={navigateToEditPage}
              >
                Update Custom Page
              </Button>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditTags;
