import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { AuthorCarouselData, AuthorListData } from '../DummyData';
import AuthorCard from './AuthorCard';
import AuthorCarousel from './AuthorCarousel';
import AuthorGrid from './AuthorGrid';
import AuthorList from './AuthorList';

const { Title } = Typography;
function AuthorWrapper() {
  return (
    <>
      <section>
        <Title level={4}>Speaker/Author Modules Component</Title>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} md={{ span: 5 }}>
            <AuthorCard speaker="Speaker Name" videoCount="15" />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <AuthorCard
              listCard
              speaker="Speaker Name"
              videoCount="10"
              description=" Lorem ipsum dolor sit amet consectetur. Vitae tristique donec libero
                   ullamcorper sit ultrices felis tristique."
            />
          </Col>
        </Row>
      </section>
      <div className="s-40" />
      <AuthorCarousel
        sectionHeading="Carousel Heading All"
        headerLink="/"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={AuthorCarouselData}
      />
      <div className="s-40" />
      <AuthorList
        separator
        sectionHeading="List Title"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={AuthorListData}
      />
      <AuthorGrid
        sectionHeading="Speaker Grid Title"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={AuthorCarouselData}
      />
      <Divider>Author responsive view Module</Divider>
      <div className="d-flex flex-wrap">
        <div className="responsive-grid">
          <AuthorCarousel
            responsive
            sectionHeading="Carousel Heading All"
            headerLink="/"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
            data={AuthorCarouselData}
          />
        </div>
        <div className="responsive-grid">
          <AuthorList
            separator
            responsive
            sectionHeading="List Title"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
            data={AuthorListData}
          />
        </div>
        <div className="responsive-grid">
          <AuthorGrid
            responsive
            sectionHeading="Speaker Grid Title"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
            data={AuthorCarouselData}
          />
        </div>
      </div>
    </>
  );
}
export default AuthorWrapper;
