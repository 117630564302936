import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Component from './Component';

const ComponentWrapper = () => (
  <Switch>
    <Route path={ROUTES?.COMPONENT} exact component={Component} />
  </Switch>
);

export default ComponentWrapper;
