import { Col, Row, Typography } from 'antd';
import { CaretRight } from 'phosphor-react';
import React from 'react';
import { TagCarouseldata } from '../DummyData';
import TagCarousel from './TagCarousel';
import TagGrid from './TagGrid';
import TagHero from './TagHero';

const { Title } = Typography;
function TagWrapper() {
  return (
    <section>
      <Title level={4}>Tag Component</Title>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <TagHero title="Deconstruction" />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <TagCarousel
            className=" tag-xs bg-n-400 b-0 text-n-900 mr-4 "
            data={TagCarouseldata}
            icon={<CaretRight size={16} />}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} />
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <TagGrid
            sectionHeading="Tag Cloud Title"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Tristique."
            className="tag-xs bg-n-400 b-0 text-n-900 mr-4  "
            data={TagCarouseldata}
            icon={<CaretRight size={16} />}
          />
        </Col>
      </Row>
      <div className="s-40" />
      <div className="d-flex flex-wrap">
        <div className="responsive-grid">
          <TagCarousel
            responsive
            className=" tag-xs bg-n-400 b-0 text-n-900 mr-4 "
            data={TagCarouseldata}
            icon={<CaretRight size={16} />}
          />
        </div>
        <div className="responsive-grid">
          <TagGrid
            responsive
            sectionHeading="Tag Cloud Title"
            headerDescription="Lorem ipsum dolor sit amet consectetur. Tristique."
            className="tag-xs bg-n-400 b-0 text-n-900 mr-4  "
            data={TagCarouseldata}
            icon={<CaretRight size={16} />}
          />
        </div>
        <div className="responsive-grid">
          <TagHero responsive title="Deconstruction" />
        </div>
      </div>
    </section>
  );
}
export default TagWrapper;
