import { Divider } from 'antd';
import React from 'react';
import Fc from '../../../assets/images/fc.png';
import FeaturedSpeakerImg from '../../../assets/images/fs.png';
import {
  FeaturedCarouselData,
  FeaturedCollectionCarouselData
} from '../DummyData';
import FeaturedCollection from './FeaturedCollection';
import FeaturedCollectionCarousel from './FeaturedCollectionCarousel';
import FeaturedSpeaker from './FeaturedSpeaker';
import FeaturedVideoCard from './FeaturedVideoCard';
import FeaturedCarousel from './FeaturedVideoCarousel';

function FeaturedWrapper() {
  return (
    <>
      <section>
        <FeaturedVideoCard
          tagName="Faith + Work"
          heading="Truth in Jest"
          subHeading="Tony Hale"
          description="Comedian and actor Tony Hale shares how comedy can tell deep truths in a way that’s both accessible and convicting."
          tagData={[
            { id: 1, name: 'Comedy' },
            { id: 2, name: 'Interview' },
            { id: 3, name: 'Influence' }
          ]}
        />
      </section>
      <section className="mt-20">
        <FeaturedCarousel data={FeaturedCarouselData} />
      </section>
      <section className="mt-20">
        <FeaturedCarousel canterMode={false} data={FeaturedCarouselData} />
      </section>
      <section className="mt-20">
        <FeaturedCarousel
          sectionTitle="Our Discussion Videos"
          canterMode={false}
          data={FeaturedCarouselData}
        />
      </section>
      <section className="mt-20" />
      <Divider>Featured Carousel responsive view Module</Divider>
      <div className="d-flex flex-wrap">
        <div className="responsive-grid">
          <FeaturedCarousel responsive data={FeaturedCarouselData} />
        </div>
        <div className="responsive-grid">
          <FeaturedCarousel
            responsive
            canterMode={false}
            data={FeaturedCarouselData}
          />
        </div>
        <div className="responsive-grid">
          <FeaturedCarousel
            responsive
            sectionTitle="Our Discussion Videos"
            canterMode={false}
            data={FeaturedCarouselData}
          />
        </div>
      </div>
      <div className="s-40" />
      <FeaturedCollection
        img={Fc}
        title="Women & Calling"
        videoCount="12 Videos"
        description=" This course equips women to better understand and live out their
        unique, God-given gifts."
        button="View Course"
      />
      <div className="s-40" />
      <FeaturedCollectionCarousel data={FeaturedCollectionCarouselData} />
      <div className="s-40" />

      <FeaturedSpeaker
        speakerImage={FeaturedSpeakerImg}
        speakerName="john mark comer "
        speakerDescription="Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy"
      />
      <div className="s-40" />
      <div className="d-flex flex-wrap">
        <div className="responsive-grid">
          <FeaturedCollection
            responsive
            img={Fc}
            title="Women & Calling"
            videoCount="12 Videos"
            description=" This course equips women to better understand and live out their
        unique, God-given gifts."
            button="View Course"
          />
        </div>
        <div className="responsive-grid">
          <FeaturedCollectionCarousel
            responsive
            data={FeaturedCollectionCarouselData}
          />
        </div>
        <div className="responsive-grid">
          <FeaturedSpeaker
            responsive
            speakerImage={FeaturedSpeakerImg}
            speakerName="john mark comer "
            speakerDescription="Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy"
          />
        </div>
      </div>
    </>
  );
}
export default FeaturedWrapper;
