import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import DefaultCollectionImg from '../../../assets/images/collection.png';
import { collectionList, collectionSlider } from '../DummyData';
import CollectionCard from './CollectionCard';
import CollectionCarousel from './CollectionCarousel';
import CollectionGrid from './CollectionGrid';
import CollectionHero from './CollectionHero';
import CollectionList from './CollectionList';

const { Title } = Typography;
function CollectionWrapper() {
  return (
    <>
      <section>
        <Title level={4}>Collection Component</Title>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} md={{ span: 5 }}>
            <CollectionCard
              title=" Title of Collection"
              description="# of Videos"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 4 }}>
            <CollectionCard
              collectionMd
              title=" Title of Collection"
              description="# of Videos"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 4 }}>
            <CollectionCard
              collectionLg
              title=" Title of Collection"
              description="# of Videos"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <CollectionCard
              collectionList
              title=" Title of Collection"
              videoCount="12"
              description="This is a collection of videos that are related. Maybe a podcast, series, course, or any other flexible content type."
            />
          </Col>
        </Row>
      </section>
      <CollectionHero
        src={DefaultCollectionImg}
        title="This Cultural Moment"
        episodes="80"
      />
      <CollectionCarousel
        sectionHeading="Carousel Heading"
        headerLink="/"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
        data={collectionSlider}
      />
      <CollectionCarousel
        collectionMd
        sectionHeading="Curated Podcasts"
        headerLink="/"
        headerDescription="Handpicked episodes and shows for you."
        data={collectionSlider}
      />
      <div className="s-40" />
      <div className="container-rnp">
        <CollectionList
          separator
          sectionHeading="Collection List Title"
          headerDescription="Lorem ipsum dolor sit amet consectetur. Lacus sociis magna non ornare orci in eget. Nulla amet venenatis."
          data={collectionList}
        />
      </div>
      <CollectionGrid
        sectionHeading="Collection Grid Title"
        headerDescription="Lorem ipsum dolor sit amet consectetur. Elit vel risus feugiat massa lorem consectetur libero. Molestie."
        data={collectionSlider}
      />
      <Divider>Collection responsive view Module</Divider>
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <CollectionCarousel
              responsive
              sectionHeading="Carousel Heading"
              headerLink="/"
              headerDescription="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
              data={collectionSlider}
            />
          </div>
          <div className="responsive-grid">
            <CollectionList
              separator
              responsive
              sectionHeading="Collection List Title"
              headerDescription="Lorem ipsum dolor sit amet consectetur. Lacus sociis magna non ornare orci in eget. Nulla amet venenatis."
              data={collectionList}
            />
          </div>
          <div className="responsive-grid">
            <CollectionGrid
              responsive
              sectionHeading="Collection Grid Title"
              headerDescription="Lorem ipsum dolor sit amet consectetur. Elit vel risus feugiat massa lorem consectetur libero. Molestie."
              data={collectionSlider}
            />
          </div>
          <div className="s-40 w-full" />
          <div className="responsive-grid">
            <CollectionHero
              responsive
              src={DefaultCollectionImg}
              title="This Cultural Moment"
              episodes="80"
            />
          </div>
        </div>
      </section>
    </>
  );
}
export default CollectionWrapper;
