import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import EditUILabel from './EditUILabel';
import UILabels from './UILabels';

const UILabelsWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.UI_LABELS} component={UILabels} />
      <Route
        exact
        path={`${ROUTES?.UI_LABELS}/:appType/:labelKey/edit`}
        component={EditUILabel}
      />
      <Route
        exact
        path={`${ROUTES?.UI_LABELS}/:appType`}
        render={() => <Redirect to={ROUTES?.UI_LABELS} />}
      />
      <Route
        exact
        path={`${ROUTES?.UI_LABELS}/:appType/:labelKey`}
        render={() => <Redirect to={ROUTES?.UI_LABELS} />}
      />
    </Switch>
  );
};

export default UILabelsWrapper;
