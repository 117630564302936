import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEditUser from './AddEditUser';
import Users from './Users';

const UsersWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.USERS} component={Users} />
      <Route exact path={`${ROUTES?.USERS}/add`} component={AddEditUser} />
      <Route
        exact
        path={`${ROUTES?.USERS}/:userId/edit`}
        component={AddEditUser}
      />
      <Route
        exact
        path={`${ROUTES?.USERS}/:userId`}
        render={() => <Redirect to={ROUTES?.USERS} />}
      />
    </Switch>
  );
};

export default UsersWrapper;
