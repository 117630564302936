import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_UI_LABEL = gql`
  mutation updateUILabel(
    $key: String!
    $appType: AppType!
    $data: UpdateUILabelInputData!
  ) {
    updateUILabel(where: { key: $key, appType: $appType }, data: $data) {
      message
    }
  }
`;
