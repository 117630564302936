import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import {
  APP_TYPE_OPTIONS,
  ASSET_CATEGORY,
  MENU_TYPES,
  MENU_TYPES_OPTIONS,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  STATIC_PAGES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { SlugInput } from '../../../../labels/topics/components/FormInputs';
import { GET_SLUGS } from '../../../../menus/graphql/Queries';
import { Select, SelectAsset } from '../../../../videos/components/FormInputs';
import { useEditPage } from '../../../context/EditPageProvider';
import { UPDATE_PAGE } from '../../../graphql/Mutations';
import { Permissions, Switch } from './FormInputs';

const Settings = () => {
  const {
    setSelectionType,
    settings,
    fetchingPageDetails,
    updateSettings,
    isDefaultPage
  } = useEditPage();
  const [form] = Form.useForm();

  const redirectionActive = Form.useWatch(['redirection', 'active'], form);

  const type = Form.useWatch(['redirection', 'type'], form);

  const internalPageType = Form.useWatch(
    ['redirection', 'internalPageType'],
    form
  );

  useEffect(() => {
    form.setFieldsValue({
      ...settings,
      permissions: settings?.permissions?.map((value) => ({
        label: value,
        value
      })),
      redirection: {
        ...settings?.redirection,
        type: settings?.redirection?.type ?? MENU_TYPES.INTERNAL,
        internalPageType: settings?.redirection?.internalPageType ?? null,
        ...(settings?.redirection?.type === MENU_TYPES.INTERNAL && {
          ...(settings?.redirection?.internalPageType === PAGE_TYPES.STATIC
            ? { slug: settings?.redirection?.url }
            : {
                slug: settings?.redirection?.url
                  ? {
                      label: `/${settings?.redirection?.url}`,
                      value: settings?.redirection?.url
                    }
                  : null
              })
        }),
        url:
          settings?.redirection?.type === MENU_TYPES.INTERNAL
            ? `/${settings?.redirection?.url}`
            : settings?.redirection?.url ?? ''
      }
    });
  }, [settings, form]);

  const { id } = useParams();

  const [updatePage, { loading }] = useMutation(UPDATE_PAGE);

  const handleSubmit = ({ slug, previewImage, ...values }) => {
    const payload = {
      ...values,
      permissions: values?.permissions?.map(({ value }) => value),
      ...(!isDefaultPage && {
        previewThumbnailId: previewImage?.id || null
      }),
      ...(settings?.allowDelete && {
        slug: slug.substring(1)
      }),
      ...(values?.redirection?.type === MENU_TYPES.INTERNAL && {
        redirection: {
          ...values?.redirection,
          url: values?.redirection?.url?.startsWith('/')
            ? values?.redirection?.url?.substring(1)
            : values?.redirection?.url
        }
      })
    };
    delete payload?.redirection?.slug;
    delete payload?.appType;
    updatePage({
      variables: {
        data: payload,
        where: { id }
      }
    })
      .then(
        ({
          data: {
            updatePage: { page: newSettings }
          }
        }) => {
          setSelectionType('');
          updateSettings(newSettings);
        }
      )
      .catch();
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const handleCancel = () => {
    setSelectionType('');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={settings}
      disabled={fetchingPageDetails}
    >
      <Form.Item
        label="Title"
        name="title"
        required
        rules={[
          formValidatorRules?.required('Please enter title!'),
          formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
        ]}
      >
        <Input placeholder="Enter title" onChange={handleTitleChange} />
      </Form.Item>
      <Form.Item
        label="App Type"
        name="appType"
        rules={[
          {
            required: true,
            message: 'Please select app type!'
          }
        ]}
      >
        <AntdSelect
          disabled
          options={APP_TYPE_OPTIONS}
          placeholder="Select app type"
        />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Please enter slug!'
          }
        ]}
      >
        <SlugInput
          readOnly={!settings?.allowDelete}
          disabled={!settings?.allowDelete}
        />
      </Form.Item>

      <Row>
        <Col span={24} className="redirection-section">
          <Typography.Title level={4}>Redirect URL</Typography.Title>
          <Form.Item
            className="m-0"
            name={['redirection', 'active']}
            valuePropName="checked"
          >
            <Switch label="Active" />
          </Form.Item>
          <Form.Item
            label="Type"
            className="mt-16"
            name={['redirection', 'type']}
            rules={[
              {
                required: redirectionActive,
                message: 'Please select type!'
              }
            ]}
          >
            <AntdSelect
              options={MENU_TYPES_OPTIONS}
              placeholder="Select type"
              onChange={(value) => {
                form.setFieldValue(
                  ['redirection', 'newTab'],
                  value !== MENU_TYPES.INTERNAL
                );
                form.resetFields([
                  ['redirection', 'url'],
                  ['redirection', 'slug'],
                  ['redirection', 'internalPageType']
                ]);
              }}
            />
          </Form.Item>
          {type === MENU_TYPES.INTERNAL && (
            <>
              <Form.Item
                label="Page Type"
                name={['redirection', 'internalPageType']}
                rules={[
                  {
                    required: redirectionActive && type === MENU_TYPES.INTERNAL,
                    message: 'Please select page type!'
                  }
                ]}
              >
                <AntdSelect
                  options={PAGE_TYPES_OPTIONS}
                  placeholder="Select page type"
                  onChange={() => {
                    form.resetFields([
                      ['redirection', 'url'],
                      ['redirection', 'slug']
                    ]);
                  }}
                />
              </Form.Item>
              {internalPageType && (
                <>
                  {internalPageType === PAGE_TYPES.STATIC ? (
                    <Form.Item
                      label="Page"
                      name={['redirection', 'slug']}
                      rules={[
                        {
                          required:
                            redirectionActive && type === MENU_TYPES.INTERNAL,
                          message: 'Please select page!'
                        }
                      ]}
                    >
                      <AntdSelect
                        options={STATIC_PAGES}
                        onChange={(value) => {
                          form.setFieldValue(
                            ['redirection', 'url'],
                            `/${value}`
                          );
                          form.validateFields([['redirection', 'url']]);
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Page"
                      name={['redirection', 'slug']}
                      rules={[
                        {
                          required:
                            redirectionActive && type === MENU_TYPES.INTERNAL,
                          message: 'Please select page!'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        query={GET_SLUGS}
                        variablesSelector={(filter) => ({
                          filter: {
                            ...filter,
                            pageType: internalPageType
                          }
                        })}
                        dataSelector={(data) => {
                          return (
                            data?.slugs?.slugs?.map(({ slug }) => ({
                              label: `/${slug}`,
                              value: slug
                            })) ?? []
                          );
                        }}
                        keys={{
                          data: 'slugs',
                          records: 'slugs',
                          count: 'count'
                        }}
                        onChange={({ value }) => {
                          form.setFieldValue(
                            ['redirection', 'url'],
                            `/${value}`
                          );
                          form.validateFields([['redirection', 'url']]);
                        }}
                      />
                    </Form.Item>
                  )}
                </>
              )}
            </>
          )}
          <Form.Item
            label="URL"
            name={['redirection', 'url']}
            rules={[
              {
                required: redirectionActive,
                message: 'Please enter URL!'
              }
            ]}
          >
            <Input
              placeholder="Enter url"
              readOnly={type === MENU_TYPES.INTERNAL}
              disabled={type === MENU_TYPES.INTERNAL}
            />
          </Form.Item>
          <Form.Item
            className="m-0"
            name={['redirection', 'newTab']}
            valuePropName="checked"
          >
            <Switch label="Open In New Tab" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Description" name="description">
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>
      {!isDefaultPage && (
        <Form.Item label="Page Sharing Thumbnail" name="previewImage">
          <SelectAsset
            modalTitle="Select Page Sharing Thumbnail"
            categoryKey={ASSET_CATEGORY.IMAGE}
            btnText="Image Thumbnail"
            dataSelector={({ id: assetId, url }) => ({
              id: assetId,
              url
            })}
          />
        </Form.Item>
      )}

      <Form.Item name="metaHeader" label="Meta Header">
        <Input.TextArea rows={5} placeholder="Enter meta header" />
      </Form.Item>

      <Form.Item name="metaFooter" label="Meta Footer">
        <Input.TextArea rows={5} placeholder="Enter meta footer" />
      </Form.Item>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              disabled={fetchingPageDetails || loading}
              loading={loading}
              htmlType="submit"
              className="text-btn mr-8"
              type="text"
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="text"
              disabled={fetchingPageDetails || loading}
              className="text-btn2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default Settings;
