import { gql } from '@apollo/client';

export const GET_UI_LABELS = gql`
  query uiLabelsAdmin($filter: UILabelsFilter!) {
    uiLabelsAdmin(filter: $filter) {
      count
      uiLabels {
        key
        appType
        value
        description
        referenceImage {
          url
          blurhash
        }
        type
      }
    }
  }
`;

export const GET_UI_LABEL = gql`
  query uiLabelAdmin($key: String!, $appType: AppType!) {
    uiLabelAdmin(where: { key: $key, appType: $appType }) {
      value
      description
      appType
      referenceImage {
        id
        url
      }
      type
    }
  }
`;
