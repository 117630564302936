import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Checkbox,
  Col,
  Row,
  Space,
  Switch,
  Tag,
  Typography
} from 'antd';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import React from 'react';
import cardImg from '../../../assets/images/collection.png';
import Card from '../card/Cscard';
import FeaturedCard from '../card/featurecard';
import SmallCard from '../card/smallCard';
import SectionAdd from '../sectionWrapper/SectionAdd';
import SectionWrapper from '../sectionWrapper/SectionWrapper';

const { Title } = Typography;
function SmallComponentWrapper() {
  return (
    <>
      <section>
        <Title level={4}>avatar</Title>
        <Space wrap>
          <Avatar icon="AB" size="small" />
          <Avatar icon="AB" size="default" />
          <Avatar icon="AB" size="large" />
        </Space>
      </section>
      <section>
        <Title level={4}>Tags</Title>
        <Space wrap>
          <Tag className="tag-small">
            <LeftOutlined />
            Tag <RightOutlined />
          </Tag>
          <Tag>
            {' '}
            <LeftOutlined />
            Tag <RightOutlined />
          </Tag>
          <Tag className="tag-large">
            <LeftOutlined />
            Tag <RightOutlined />
          </Tag>
        </Space>
        <Title level={4}>Badge</Title>
        <Space className="mt-16 mb-16" wrap>
          <Badge className="badge-l bg-red-2 text-n-100">Category</Badge>
          <Badge className="badge-md bg-moss-2 text-n-1000">Category</Badge>
          <Badge className="badge-xs bg-yellow-2 text-n-1000">Category</Badge>
        </Space>
      </section>
      <section>
        <Title level={4}>Switch</Title>
        <div>
          <Switch defaultChecked />
        </div>
        <div>
          <Switch />
        </div>
      </section>
      <section>
        <Title level={4}>Card</Title>

        <Row gutter={[12, 12]} align="middle">
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <div className="heading-h6 text-n-900 mb-8">Small Card</div>
            <SmallCard
              src={cardImg}
              heading="Title goes here "
              description="Eaque adipisci sapiente modi est rerum beatae dicta adipisci eos. Sit
               assumenda"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <div className="heading-h6 text-n-900 mb-8"> Card</div>
            <Card
              src={cardImg}
              title="Kicker"
              heading=" This is the Title "
              description=" Eaque adipisci sapiente modi est rerum beatae dicta adipisci eos. Sit
                  assumenda"
              primary={
                <>
                  <PencilSimpleLine size={16} />
                  Edit
                </>
              }
              secondary={
                <>
                  <Trash size={16} />
                  Delete
                </>
              }
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <div className="heading-h6 text-n-900 mb-8">Featured Card</div>
            <FeaturedCard
              src={cardImg}
              title="Secondary Text"
              heading="Title Goes Here"
              primary="Primary"
            />
          </Col>
        </Row>
      </section>
      <section>
        <Title level={4}>checkbox</Title>
        <Checkbox className="text-md" checked="checked">
          Label
        </Checkbox>
        <Checkbox className="text-md">Label</Checkbox>
      </section>
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid p-0">
            <SectionAdd />
            <SectionWrapper />
            <SectionAdd />
          </div>
        </div>
      </section>
    </>
  );
}
export default SmallComponentWrapper;
