import { PlayCircle } from 'phosphor-react';
import React from 'react';
import InfoImg from '../../../assets/images/information.png';
import Hvbg from '../../../assets/images/videos.png';
import { faqData, quotedata } from '../DummyData';
import Contact from './contact/Contact';
import CtaBanner from './ctaModule/CtaBanner';
import CtaMarketingBlock from './ctaModule/CtaMarketingBlock';
import Donate from './donate/Donate';
import FaqModule from './faqModule/FaqModule';
import HeavyModule from './heavyModule/HeavyModule';
import HeroBlock from './heroModules/HeroBlock';
import InformationBlock from './informationModule/InformationBlock';
import NewsLetter from './newsLatter/NewsLetter';
import QuoteSlider from './quoteModule/QuoteSlider';

function MarketingComponent() {
  return (
    <div>
      <div className="container">
        <div className="heading-h5 mb-16">Hero Module</div>
      </div>
      <HeroBlock
        imageSrc={Hvbg}
        title="Culture Summit 2023: Building Resilient Communities"
        description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
        date="April 28th @ 5:00 AM"
        location="Nashville, TN"
        cta1="Learn more"
        cta2={
          <>
            <PlayCircle className="mr-4 ml-0" size={22} /> Who We Are
          </>
        }
      />
      <div className="s-40" />
      <HeroBlock
        videoSrc="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
        title="Culture Summit 2023: Building Resilient Communities"
        description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
        date="April 28th @ 5:00 AM"
        location="Nashville, TN"
        cta1="Learn more"
        cta2={
          <>
            <PlayCircle className="mr-4 ml-0" size={22} /> Who We Are
          </>
        }
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <HeroBlock
              responsive
              imageSrc={Hvbg}
              title="Culture Summit 2023: Building Resilient Communities"
              description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
              date="April 28th @ 5:00 AM"
              location="Nashville, TN"
              cta1="Learn more"
              cta2={
                <>
                  <PlayCircle className="mr-4 ml-0" size={22} /> Who We Are
                </>
              }
            />
          </div>
          <div className="responsive-grid">
            <HeroBlock
              responsive
              videoSrc="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
              title="Culture Summit 2023: Building Resilient Communities"
              description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
              date="April 28th @ 5:00 AM"
              location="Nashville, TN"
              cta1="Learn more"
              cta2={
                <>
                  <PlayCircle className="mr-4 ml-0" size={22} /> Who We Are
                </>
              }
            />
          </div>
        </div>
      </section>
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Quote Module</div>
      </div>
      <QuoteSlider data={quotedata} />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <QuoteSlider responsive data={quotedata} />
          </div>
        </div>
      </section>
      <div className="s-40" />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Information Module</div>
      </div>
      <InformationBlock
        imgSrc={InfoImg}
        title="Initiate Important Conversations with Your Family"
        description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
              turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
              amet consectetur."
      />
      <br />
      <InformationBlock
        reverse
        imgSrc={InfoImg}
        title="Initiate Important Conversations with Your Family"
        description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
              turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
              amet consectetur."
      />
      <InformationBlock
        background="bg-red-3"
        darkBackground
        reverse
        imgSrc={InfoImg}
        title="Initiate Important Conversations with Your Family"
        description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
              turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
              amet consectetur."
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <InformationBlock
              responsive
              imgSrc={InfoImg}
              title="Initiate Important Conversations with Your Family"
              description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
              turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
              amet consectetur."
            />
          </div>
          <div className="responsive-grid">
            <InformationBlock
              size="SQUARE"
              responsive
              reverse
              imgSrc={InfoImg}
              title="Initiate Important Conversations with Your Family"
              description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
              turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
              amet consectetur."
            />
          </div>
          <div className="responsive-grid">
            <InformationBlock
              responsive
              size="PORTRAIT"
              background="bg-red-3"
              darkBackground
              reverse
              imgSrc={InfoImg}
              title="Initiate Important Conversations with Your Family"
              description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
              turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
              amet consectetur."
            />
          </div>
        </div>
      </section>
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">News Letter Module</div>
      </div>
      <NewsLetter
        title="Subscribe to THINQ"
        description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
      />
      <br />
      <NewsLetter
        background="bg-red-3"
        darkBackground
        title="Subscribe to THINQ"
        description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <NewsLetter
              responsive
              title="Subscribe to THINQ"
              description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
            />
          </div>
          <div className="responsive-grid">
            <NewsLetter
              responsive
              background="bg-red-3"
              darkBackground
              title="Subscribe to THINQ"
              description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
            />
          </div>
        </div>
      </section>
      <div className="s-40" />
      <Donate
        videoTitle="Watch the team share THINQ’s vision"
        title="Help Advance Good"
        description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
      />
      <br />
      <Donate
        videoTitle="Watch the team share THINQ’s vision"
        title="Help Advance Good"
        description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
        videoSectionTitle=" Initiate Important Conversations with Your Family"
        videoSectionSubTitle="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper
        pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum
        dolor sit amet consectetur."
      />
      <br />
      <Donate
        videoTitle="Watch the team share THINQ’s vision"
        background="bg-n-800"
        videoBackground="bg-n-900"
        gradientBackground
        sectionDark
        darkBackgroundVideo
        title="Help Advance Good"
        description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
        // videoSectionTitle=" Initiate Important Conversations with Your Family"
        videoSectionSubTitle="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper
        pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum
        dolor sit amet consectetur."
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <Donate
              videoTitle="Watch the team share THINQ’s vision"
              responsive
              title="Help Advance Good"
              description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
            />
          </div>
          <div className="responsive-grid">
            <Donate
              videoTitle="Watch the team share THINQ’s vision"
              responsive
              title="Help Advance Good"
              description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
              videoSectionTitle=" Initiate Important Conversations with Your Family"
              videoSectionSubTitle="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper
        pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum
        dolor sit amet consectetur."
            />
          </div>
          <div className="responsive-grid">
            <Donate
              videoTitle="Watch the team share THINQ’s vision"
              responsive
              background="bg-n-800"
              videoBackground="bg-n-900"
              gradientBackground
              sectionDark
              darkBackgroundVideo
              title="Help Advance Good"
              description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
              // videoSectionTitle=" Initiate Important Conversations with Your Family"
              videoSectionSubTitle="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper
        pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum
        dolor sit amet consectetur."
            />
          </div>
        </div>
      </section>
      <div className="s-40" />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Heavy Module </div>
      </div>
      <HeavyModule
        title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
        authorImage="/images/fs.png"
        authorName="Malcolm Gladwell"
        authorSubTitle="Sub title of who they are"
        date="Date 12/12/22"
        secondaryButton="Learn More"
        primaryButton="Register Now"
        cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <h4>Lorem ipsum dolor sit amet consectetur.</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
      />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Heavy Module center </div>
      </div>
      <HeavyModule
        centerAlign
        title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
        authorImage="/images/fs.png"
        authorName="Malcolm Gladwell"
        authorSubTitle="Sub title of who they are"
        date="Date 12/12/22"
        secondaryButton="Learn More"
        primaryButton="Register Now"
        cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <h4>Lorem ipsum dolor sit amet consectetur.</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <HeavyModule
              responsive
              title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
              authorImage="/images/fs.png"
              authorName="Malcolm Gladwell"
              authorSubTitle="Sub title of who they are"
              date="Date 12/12/22"
              secondaryButton="Learn More"
              primaryButton="Register Now"
              cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <h4>Lorem ipsum dolor sit amet consectetur.</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
            />
          </div>
          <div className="responsive-grid">
            <HeavyModule
              responsive
              centerAlign
              title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
              authorImage="/images/fs.png"
              authorName="Malcolm Gladwell"
              authorSubTitle="Sub title of who they are"
              date="Date 12/12/22"
              secondaryButton="Learn More"
              primaryButton="Register Now"
              cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <h4>Lorem ipsum dolor sit amet consectetur.</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
            />
          </div>
        </div>
      </section>
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Havey hero Module </div>
      </div>
      <HeavyModule
        heroModule
        title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
        authorImage="/images/fs.png"
        authorName="Malcolm Gladwell"
        authorSubTitle="Sub title of who they are"
        date="April 28th @ 5:00 AM"
        secondaryButton="Learn More"
        primaryButton="Register Now"
        cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
      />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Havey hero Module center </div>
      </div>
      <HeavyModule
        centerAlign
        heroModule
        title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
        authorImage="/images/fs.png"
        authorName="Malcolm Gladwell"
        authorSubTitle="Sub title of who they are"
        date="April 28th @ 5:00 AM"
        secondaryButton="Learn More"
        primaryButton="Register Now"
        cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <HeavyModule
              responsive
              heroModule
              title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
              authorImage="/images/fs.png"
              authorName="Malcolm Gladwell"
              authorSubTitle="Sub title of who they are"
              date="April 28th @ 5:00 AM"
              secondaryButton="Learn More"
              primaryButton="Register Now"
              cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
            />
          </div>
          <div className="responsive-grid">
            <HeavyModule
              responsive
              centerAlign
              heroModule
              title=" THINQ is a culture awareness platform that takes a unique approach
            to promoting cultural sensitivity and understanding."
              authorImage="/images/fs.png"
              authorName="Malcolm Gladwell"
              authorSubTitle="Sub title of who they are"
              date="April 28th @ 5:00 AM"
              secondaryButton="Learn More"
              primaryButton="Register Now"
              cmsContent="<p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur. Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium
        turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit
        amet consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
        sit amet consectetur. Dictum ullamcorper pretium turpis vitae
        tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet
        consectetur.Lorem ipsum dolor sit amet consectetur. Dictum
        ullamcorper pretium turpis vitae tellus eget arcu amet
        faucibus.Lorem ipsum dolor sit amet consectetur.
      </p>"
            />
          </div>
        </div>
      </section>
      <div className="s-40" />

      <div className="container">
        <div className="heading-h5 mb-16 mt-16">
          CtaBanner with background Image
        </div>
      </div>
      <CtaBanner
        cta="Who We Are"
        background="bg-blue-2"
        ctaBackgroundImage={Hvbg}
        ctaBackgroundDark
        title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
        descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
      />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">
          CtaBanner without background
        </div>
      </div>
      <CtaBanner
        cta="Who We Are"
        background="bg-blue-2"
        // ctaBackgroundImage={Hvbg}
        title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
        descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
      />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">
          CtaBanner with background color
        </div>
      </div>
      <CtaBanner
        cta="Who We Are"
        // background="bg-red-2"
        ctaBackgroundColor="bg-blue-2"
        ctaBackgroundDark
        ctaBackgroundImage={Hvbg}
        title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
        descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
      />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">
          CtaBanner with background dark color
        </div>
      </div>
      <CtaBanner
        cta="Who We Are"
        background="bg-n-900"
        ctaBackgroundColor="bg-yellow-3"
        ctaBackgroundDark
        ctaBackgroundImage={Hvbg}
        title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
        descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <CtaBanner
              cta="Who We Are"
              responsive
              background="bg-blue-2"
              ctaBackgroundImage={Hvbg}
              ctaBackgroundDark
              title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
              descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
            />
          </div>
          <div className="responsive-grid">
            <CtaBanner
              cta="Who We Are"
              responsive
              background="bg-blue-2"
              // ctaBackgroundImage={Hvbg}
              title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
              descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
            />
          </div>
          <div className="responsive-grid">
            <CtaBanner
              cta="Who We Are"
              responsive
              // background="bg-red-2"
              ctaBackgroundColor="bg-blue-2"
              ctaBackgroundDark
              ctaBackgroundImage={Hvbg}
              title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
              descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
            />
          </div>
          <div className="s-40 w-full" />
          <div className="responsive-grid">
            <CtaBanner
              cta="Who We Are"
              responsive
              background="bg-n-900"
              ctaBackgroundColor="bg-yellow-3"
              ctaBackgroundDark
              ctaBackgroundImage={Hvbg}
              title="“Why THINQ is just incredible and trustworthy in a sentence or two right”"
              descriptions="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. "
            />
          </div>
        </div>
      </section>
      <div className="s-40" />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Cta Marketing Block</div>
      </div>
      <CtaMarketingBlock
        contentlight
        background="bg-blue-3"
        img={Hvbg}
        title="Cta Title Goes Here"
        date="April 28th @ 5:00 AM"
        location="Nashville, TN"
        secondary="Already Purchased"
        primary="Reserve Your Seat"
        description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <CtaMarketingBlock
              responsive
              contentlight
              background="bg-blue-3"
              img={Hvbg}
              title="Cta Title Goes Here"
              date="April 28th @ 5:00 AM"
              location="Nashville, TN"
              secondary="Already Purchased"
              primary="Reserve Your Seat"
              description=" Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
            />
          </div>
        </div>
      </section>
      <div className="s-40" />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Faq Module</div>
      </div>
      {/* Faq module start here */}
      <FaqModule
        title="A few frequently asked questions"
        subTitle="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
        data={faqData}
      />
      {/* Faq module end here */}
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <FaqModule
              responsive
              title="A few frequently asked questions"
              subTitle="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur. "
              data={faqData}
            />
          </div>
        </div>
      </section>
      <div className="s-40" />
      <div className="container">
        <div className="heading-h5 mb-16 mt-16">Contact us</div>
      </div>
      <Contact
        title="Contact Us"
        subtitle="  Lorem Ipsum dolor sit amen. Lorem Ipsum dolor sit amen. Lorem
  Ipsum dolor sit amen."
      />
      <div className="s-40" />
      <section>
        <div className="d-flex flex-wrap">
          <div className="responsive-grid">
            <Contact
              responsive
              title="Contact Us"
              subtitle="  Lorem Ipsum dolor sit amen. Lorem Ipsum dolor sit amen. Lorem
  Ipsum dolor sit amen."
            />
          </div>
        </div>
      </section>
    </div>
  );
}
export default MarketingComponent;
