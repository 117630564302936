import React from 'react';
import DefaultCollectionImg from '../../../assets/images/collection.png';
import Image from '../../../components/Image';

function CollectionHero({
  title,
  episodes,
  src,
  blurHash,
  alttitle,
  responsive
}) {
  return (
    <section
      className={`collection-hero bg-n-800 ${
        responsive ? 'responsive-view' : ''
      }`}
    >
      <div className="container">
        <div className="collection-hero-wrapper">
          {src ? (
            <div className="collection-hero-img relative">
              <Image
                blurHash={blurHash}
                src={src || DefaultCollectionImg}
                alt={alttitle}
              />
            </div>
          ) : null}

          <div className="collection-hero-description">
            {title ? (
              <div className="collection-hero-title text-n-100">{title}</div>
            ) : null}

            {episodes ? (
              <p className="m-0 text-n-100">{episodes} episodes</p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}
export default CollectionHero;
