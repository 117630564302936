import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import Collectionimg from '../../../assets/images/collection.png';
import BannerImage from './BannerImage';
import CtaCard from './CtaCard';

const { Title } = Typography;
function CtaWrapper() {
  return (
    <section>
      <Title level={4}>CTA block Component</Title>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <CtaCard
            title="Cta Title Goes Here"
            primary="primary"
            description=" Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae
                pellentesque. Sapien integer placerat in in sit id sit donec amet."
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <CtaCard
            img={Collectionimg}
            title="Cta Title Goes Here"
            secondary="secondary"
            primary="primary"
            description=" Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae
                pellentesque. Sapien integer placerat in in sit id sit donec amet."
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <CtaCard
            img={Collectionimg}
            title="Cta Title Goes Here"
            secondary="secondary"
            primary="primary"
            background="bg-moss-2"
            description=" Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae
                pellentesque. Sapien integer placerat in in sit id sit donec amet."
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <CtaCard
            img={Collectionimg}
            title="Cta Title Goes Here"
            secondary="secondary"
            primary="primary"
            background="bg-yellow-2"
            description=" Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae
                pellentesque. Sapien integer placerat in in sit id sit donec amet."
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <CtaCard
            img={Collectionimg}
            title="Cta Title Goes Here"
            contentlight
            secondary="secondary"
            primary="primary"
            background="bg-n-800"
            description=" Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae
                pellentesque. Sapien integer placerat in in sit id sit donec amet."
          />
        </Col>
      </Row>
      <div className="s-40" />
      <Divider className="mt-16 mb-16">Cta banner image</Divider>
      <BannerImage src={Collectionimg} />
      <div className="s-40" />
      <Divider className="mt-16 mb-16">Video responsive view Module</Divider>
      <div className="d-flex flex-wrap">
        <div className="responsive-grid">
          <CtaCard
            responsive
            img={Collectionimg}
            title="Cta Title Goes Here"
            secondary="secondary"
            primary="primary"
            background="bg-yellow-2"
            description=" Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae
                pellentesque. Sapien integer placerat in in sit id sit donec amet."
          />
        </div>
        <div className="responsive-grid">
          <BannerImage responsive src={Collectionimg} />
        </div>
      </div>
    </section>
  );
}
export default CtaWrapper;
