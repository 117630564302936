import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import {
  DATE_FORMAT,
  DAYS,
  LIST_TYPES,
  MODULES,
  TABLE_LOCATION_TYPES
} from '../../common/constants';
import { getConversationTime } from '../../common/utils';
import Avatar from '../../components/Avatar';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { TOGGLE_TABLE_STATUS } from './graphql/Mutations';
import { GET_TABLES } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    search,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ tablesAdmin }) => ({
  data: tablesAdmin?.tables ?? [],
  count: tablesAdmin?.count ?? 0
});

const Action = ({ id, isDisabled }) => {
  const [value, setValue] = useState(false);

  const [toggleStatus, { loading }] = useMutation(TOGGLE_TABLE_STATUS);

  useEffect(() => {
    setValue(isDisabled);
  }, [isDisabled]);

  const handleChange = () => {
    const newValue = !value;
    toggleStatus({
      variables: {
        id,
        isDisabled: newValue
      }
    })
      .then(({ errors }) => {
        setValue(newValue);
        if (errors) setValue(!newValue);
      })
      .catch(() => {
        setValue(!newValue);
      });
  };

  return (
    <Button
      loading={loading}
      onClick={handleChange}
      type="text"
      className="text-btn"
    >
      {value ? 'ENABLE' : 'DISABLE'}
    </Button>
  );
};

const columns = [
  {
    title: 'Avatar',
    dataIndex: 'profileImage',
    width: 100,
    render: (_, { createdBy: { firstName, lastName, profileImage } }) => (
      <Avatar src={profileImage} firstName={firstName} lastName={lastName} />
    )
  },
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: 'RSVPs',
    dataIndex: 'rsvpCount',
    width: 80
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    render: (_, { startDate, timezone }) => {
      return moment(startDate).tz(timezone).format(DATE_FORMAT);
    }
  },
  {
    title: 'Days',
    dataIndex: 'days',
    render: (_, { days }) => {
      return days?.map?.((day) => DAYS[day]).join?.(',');
    }
  },
  {
    title: 'Time',
    dataIndex: 'startDate',
    render: (_, { startDate, timezone }) =>
      getConversationTime(startDate, timezone)
  },
  {
    title: 'Duration',
    dataIndex: 'endDate',
    render: (_, { startDate, endDate, timezone }) => {
      const diff = moment(endDate)
        .tz(timezone)
        .diff(moment(startDate).tz(timezone), 'weeks');
      return (
        <>
          {diff} {diff <= 1 ? 'Week' : 'Weeks'}
        </>
      );
    }
  },

  {
    title: 'Loc Type',
    dataIndex: 'locationType',
    render: (key) => TABLE_LOCATION_TYPES[key] ?? '-'
  },
  {
    title: 'Loc Details',
    dataIndex: 'location',
    ellipsis: true
  },
  {
    title: 'Videos',
    dataIndex: 'videoCount'
  },

  {
    title: ' ',
    dataIndex: 'id',
    render: (_, record) => <Action {...record} />
  }
];

const Tables = () => {
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: [
        { name: 'Created At', value: 'createdAt' },
        { name: 'Name', value: 'name' }
      ]
    }
  });

  return (
    <>
      <PageHeader menu={MODULES?.CONVERSATIONS} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Conversation' }}
        show={{ listModes: false }}
      />
      <PageList
        filters={filterProps.filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_TABLES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={20}
      />
    </>
  );
};

export default Tables;
