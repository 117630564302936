import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TOGGLE_TABLE_STATUS = gql`
  mutation updateTableAdmin($id: ID!, $isDisabled: Boolean) {
    updateTableAdmin(where: { id: $id }, data: { isDisabled: $isDisabled }) {
      message
    }
  }
`;
