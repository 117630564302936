import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_TABLES = gql`
  query tablesAdmin($filter: TablesFilter, $sort: TablesSort) {
    tablesAdmin(filter: $filter, sort: $sort) {
      tables {
        id
        slug
        name
        status
        locationType
        location
        startDate
        endDate
        days
        timezone
        isDisabled
        videoCount
        rsvpCount
        createdBy {
          profileImage
          firstName
          lastName
        }
      }
      count
    }
  }
`;
