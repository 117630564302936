import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Divider,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect } from 'react';
import {
  ALIGNMENTS,
  MODULE_TYPES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  STATIC_PAGES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  TEXT_ALIGNMENTS
} from '../../../../../common/constants';
import { GET_SLUGS } from '../../../../menus/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  ACTION_TYPES,
  ACTION_TYPE_OPTIONS,
  ALIGNMENT_OPTIONS,
  getActionData
} from './CTAForm';
import { Permissions, ShowFields, Switch } from './FormInputs';

const TITLE_SIZES = {
  H1: '1',
  H2: '2',
  H3: '3',
  H4: '4',
  H5: '5',
  H6: '6'
};

const CTA_PARAGRAPH_SIZES_TITLE = {
  SMALL: 'Small',
  MEDIUM: 'Medium',
  LARGE: 'Large',
  EXTRA_LARGE: 'Extra Large'
};

const CTA_SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE'
};

const PARAGRAPH_SIZES = {
  ...CTA_SIZES
};

const initialParagraphValues = {
  text: '',
  alignment: TEXT_ALIGNMENTS.LEFT,
  size: PARAGRAPH_SIZES.MEDIUM,
  toggles: {
    underline: false,
    strikeThrough: false,
    emphasize: false,
    bold: false,
    bullet: false,
    blockQuote: false,
    visible: true
  }
};

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true,
    cta: true
  },
  config: {
    title: {
      text: '',
      alignment: TEXT_ALIGNMENTS.LEFT,
      size: TITLE_SIZES.H6,
      toggles: {
        underline: false,
        strikeThrough: false,
        emphasize: false,
        bold: false,
        bullet: false
      }
    },
    paragraphs: [initialParagraphValues],
    cta: {
      title: '',
      type: ACTION_TYPES.INTERNAL,
      internalPageType: null,
      slug: null,
      url: '',
      alignment: ALIGNMENTS.LEFT,
      size: CTA_SIZES.SMALL
    }
  }
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.HTML_TEXT_BLOCK]
  },
  {
    name: 'cta',
    label: 'CTA Hyperlink',
    allowedTypes: [MODULE_TYPES.HTML_TEXT_BLOCK]
  }
];

const TITLE_SIZE_OPTIONS = [
  { label: 'Heading 1', value: TITLE_SIZES.H1 },
  { label: 'Heading 2', value: TITLE_SIZES.H2 },
  { label: 'Heading 3', value: TITLE_SIZES.H3 },
  { label: 'Heading 4', value: TITLE_SIZES.H4 },
  { label: 'Heading 5', value: TITLE_SIZES.H5 },
  { label: 'Heading 6', value: TITLE_SIZES.H6 }
];

const mapper = ([key, value]) => ({
  value,
  label: CTA_PARAGRAPH_SIZES_TITLE[key]
});

const PARAGRAPH_SIZES_OPTIONS = Object.entries(PARAGRAPH_SIZES).map(mapper);

const CTA_SIZES_OPTIONS = Object.entries(CTA_SIZES).map(mapper);

const MODULE_KEYS = {
  [MODULE_TYPES.HTML_TEXT_BLOCK]: 'htmlTextBlockModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.HTML_TEXT_BLOCK]: 'HTML Text Block Configs'
};

const Toggles = ({ namePrefix, data, ...rest }) => {
  return (
    <Space direction="vertical" className="w-full">
      <Typography.Text>Toggles</Typography.Text>
      <div>
        {data.map(({ name, label }) => (
          <Form.Item
            className="m-0"
            key={name}
            name={[...namePrefix, name]}
            valuePropName="checked"
            {...rest}
          >
            <Switch label={label} />
          </Form.Item>
        ))}
      </div>
    </Space>
  );
};

const toggles = [
  { name: 'underline', label: 'UNDERLINE' },
  { name: 'strikeThrough', label: 'STRIKE_THROUGH' },
  { name: 'emphasize', label: 'EMPHASIZE' },
  { name: 'bold', label: 'BOLD' },
  { name: 'bullet', label: 'BULLET' }
];

const paragraphToggles = [
  { name: 'visible', label: 'VISIBLE' },
  ...toggles,
  { name: 'blockQuote', label: 'BLOCK QUOTE' }
];

const Title = () => {
  const name = ['config', 'title'];

  return (
    <>
      <Form.Item label="Title Size" name={[...name, 'size']}>
        <AntdSelect options={TITLE_SIZE_OPTIONS} />
      </Form.Item>
      <Form.Item label="Title Alignment" name={[...name, 'alignment']}>
        <AntdSelect options={ALIGNMENT_OPTIONS} />
      </Form.Item>
      <Form.Item label="Title Text" name={[...name, 'text']}>
        <Input.TextArea rows={4} placeholder="Enter text" />
      </Form.Item>
      <Toggles namePrefix={[...name, 'toggles']} data={toggles} />
    </>
  );
};

const Paragraphs = () => {
  return (
    <Form.List name={['config', 'paragraphs']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, key, ...rest }) => (
            <React.Fragment key={key}>
              <Form.Item label="Paragraph Size" name={[name, 'size']} {...rest}>
                <AntdSelect options={PARAGRAPH_SIZES_OPTIONS} />
              </Form.Item>
              <Form.Item
                label="Paragraph Alignment"
                name={[name, 'alignment']}
                {...rest}
              >
                <AntdSelect options={ALIGNMENT_OPTIONS} />
              </Form.Item>
              <Form.Item label="Paragraph Text" name={[name, 'text']} {...rest}>
                <Input.TextArea rows={4} placeholder="Enter text" />
              </Form.Item>
              <Toggles
                namePrefix={[name, 'toggles']}
                data={paragraphToggles}
                {...rest}
              />
              {fields?.length > 1 && (
                <Button className="mt-12" onClick={() => remove(name)}>
                  Remove Paragraph
                </Button>
              )}
              <Divider />
            </React.Fragment>
          ))}

          <Button
            onClick={() => add(initialParagraphValues)}
            icon={<PlusOutlined />}
          >
            Add Paragraph
          </Button>
        </>
      )}
    </Form.List>
  );
};

const CTA = ({ form, appType }) => {
  const name = ['config', 'cta'];
  const { internalPageType, type } = Form.useWatch([...name], form) ?? {};

  return (
    <>
      <Form.Item label="CTA Size" name={[...name, 'size']}>
        <AntdSelect options={CTA_SIZES_OPTIONS} />
      </Form.Item>
      <Form.Item label="CTA Alignment" name={[...name, 'alignment']}>
        <AntdSelect options={ALIGNMENT_OPTIONS} />
      </Form.Item>
      <Form.Item label="CTA Text" name={[...name, 'title']}>
        <Input placeholder="Enter text" />
      </Form.Item>
      <Form.Item label="Type" name={[...name, 'type']}>
        <AntdSelect
          options={ACTION_TYPE_OPTIONS}
          onChange={() => {
            form.resetFields([
              [...name, 'internalPageType'],
              [...name, 'slug'],
              [...name, 'url']
            ]);
          }}
        />
      </Form.Item>
      {type === ACTION_TYPES.INTERNAL && (
        <>
          <Form.Item label="Page Type" name={[...name, 'internalPageType']}>
            <AntdSelect
              options={PAGE_TYPES_OPTIONS}
              placeholder="Select page type"
              onChange={() => {
                form.resetFields([
                  [...name, 'slug'],
                  [...name, 'url']
                ]);
              }}
            />
          </Form.Item>
          {internalPageType && (
            <>
              {internalPageType === PAGE_TYPES.STATIC ? (
                <Form.Item label="Page" name={[...name, 'slug']}>
                  <AntdSelect
                    options={STATIC_PAGES}
                    onChange={(value) => {
                      form.setFieldValue([...name, 'url'], `${value}`);
                    }}
                    placeholder="Select page"
                  />
                </Form.Item>
              ) : (
                <Form.Item label="Page" name={[...name, 'slug']}>
                  <Select
                    placeholder="Select"
                    query={GET_SLUGS}
                    variablesSelector={(filter) => ({
                      filter: {
                        ...filter,
                        pageType: internalPageType,
                        appType
                      }
                    })}
                    dataSelector={(data) => {
                      return (
                        data?.slugs?.slugs?.map(({ slug }) => ({
                          label: `/${slug}`,
                          value: slug
                        })) ?? []
                      );
                    }}
                    keys={{
                      data: 'slugs',
                      records: 'slugs',
                      count: 'count'
                    }}
                    onChange={({ value }) => {
                      form.setFieldValue([...name, 'url'], `${value}`);
                    }}
                  />
                </Form.Item>
              )}
            </>
          )}
        </>
      )}
      <Form.Item label="URL" name={[...name, 'url']}>
        <Input
          readOnly={type === ACTION_TYPES.INTERNAL}
          disabled={type === ACTION_TYPES.INTERNAL}
          placeholder="Enter url"
        />
      </Form.Item>
    </>
  );
};

const HtmlTextBlockForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange,
  appType
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;

  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange(settingsProps);
    }
  }, [settingsProps]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          title: defaultValues?.moduleData?.config?.title,
          paragraphs:
            defaultValues?.moduleData?.config?.paragraphs?.map(
              ({
                __typename,
                toggles: { __typename: _, ...restToggles } = {},
                ...rest
              }) => ({
                ...rest,
                toggles: { ...restToggles }
              })
            ) ?? [],
          cta: getActionData(defaultValues?.moduleData?.config?.cta, false)
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const {
      cta: { slug: _, ...restCtaConfigs },
      ...restConfigs
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfigs,
          cta: { ...restCtaConfigs }
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item label="Module Status" name="status">
        <AntdSelect
          options={STATUS_OPTIONS.map(({ name, value }) => ({
            label: name,
            value
          }))}
          placeholder="Select status"
        />
      </Form.Item>
      <div className="mb-12">
        <ShowFields settings={SETTINGS} type={type} />
      </div>
      <Space className="w-full" direction="vertical">
        <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
        <div>
          <Title />
          <Divider />
          <Paragraphs />
          <Divider />
          <CTA form={form} appType={appType} />
          <Divider />
          <Permissions />
        </div>
      </Space>
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default HtmlTextBlockForm;
